import { http } from '@kaaylabs/kaaylabs-http-package';
import { getApiUrl } from './environment';
import { get, isEmpty } from 'lodash';
import axios from 'axios';

const baseUrl = getApiUrl();

// User URLS
export const getUser = (parameters) => http.get(`${baseUrl}/user`, parameters);
export const deleteUser = (userID) => http.del(`${baseUrl}/user/${userID}`);
export const createUser = (details) => http.post(`${baseUrl}/user/`, details);
export const editUser = (parameters, userID) =>
  http.put(`${baseUrl}/user/${userID}`, parameters);
export const userAuthenticate = (parameters) =>
  http.post(`${baseUrl}/user/login`, parameters);
export const getRole = () => http.get(`${baseUrl}/role`);
export const forgotPassword = (parameters) =>
  http.post(`${baseUrl}/user/forgetPassword`, parameters);
export const resetPassword = (parameters) =>
  http.put(`${baseUrl}/user/resetPassword`, parameters);
// Tenant URLS
export const getAllTenants = (parameters) =>
  http.get(`${baseUrl}/tenant`, parameters);
export const getAllChatTenant = (parameters) =>
  http.get(`${baseUrl}/tenant/chat`, parameters);
export const deleteTenant = (tenantID) =>
  http.del(`${baseUrl}/tenant/${tenantID}`);
export const getTenant = (tenantID) =>
  http.get(`${baseUrl}/tenant/${tenantID}`);
export const createTenant = (details) =>
  http.post(`${baseUrl}/tenant`, details);
export const createPublicTenant = (details) =>
  http.post(`${baseUrl}/tenant/public-tenant-create`, details);
export const publicTenantUrlValidation = (details) =>
  http.get(`${baseUrl}/tenant/public-tenant-url-validation`, details);
export const createTenantDetails = (tenantID) =>
  http.get(`${baseUrl}/tenant/create/${tenantID}`);
export const getDns = (parameters) =>
  http.get(`${baseUrl}/tenant/dns`, parameters);
export const editTenant = (parameters, tenantID) =>
  http.put(`${baseUrl}/tenant/${tenantID}`, parameters);
export const campaignStatus = (parameters, tenantID) =>
  http.put(`${baseUrl}/tenant/campaign/${tenantID}`, parameters);
export const tenantLogin = (parameters, tenantID) =>
  http.post(`${baseUrl}/tenant/login/${tenantID}`, parameters);
export const tenantStore = (tenantID, parameters) =>
  http.post(`${baseUrl}/tenant/store/${tenantID}`, parameters);
export const tenantAllOrders = (tenantID, parameters) =>
  http.post(`${baseUrl}/tenant/orderDetail/${tenantID}`, parameters);
export const singleOrderPdfDownload = (orderID, parameters) =>
  http.post(`${baseUrl}/tenant/singleOrderPdf/${orderID}`, parameters);
export const tenantCustomer = (tenantID, parameters) =>
  http.post(`${baseUrl}/tenant/customer/${tenantID}`, parameters);
export const tenantOverview = (tenantID, parameters) =>
  http.post(`${baseUrl}/tenant/overview/${tenantID}`, parameters);
export const tenantOrder = (tenantID, parameters) =>
  http.post(`${baseUrl}/tenant/order/${tenantID}`, parameters);
export const tenantWalletBalance = (parameters) =>
  http.post(`${baseUrl}/tenant/add-wallet-balance`, parameters);

// Setting URL's
export const getConfigList = (parameters) =>
  http.get(`${baseUrl}/setting/`, parameters);

export const getSecretValue = (tenantID) =>
  http.get(`${baseUrl}/setting/secret/${tenantID}`);
export const createTenantConfig = (tenantId, userId, parameters) =>
  http.post(
    `${baseUrl}/setting/secret/tenant-config?tenant_uid=${tenantId}&user_uid=${userId}`,
    parameters
  );
export const getB2cSecret = (tenantID) =>
  http.get(`${baseUrl}/setting/secret/b2c/${tenantID}`);
export const putB2cSecret = (tenantID, parameters) =>
  http.put(`${baseUrl}/setting/secret/b2c/${tenantID}`, parameters);
export const getCpSecret = (tenantID) =>
  http.get(`${baseUrl}/setting/secret/cp/${tenantID}`);
export const putCpSecret = (tenantID, parameters) =>
  http.put(`${baseUrl}/setting/secret/cp/${tenantID}`, parameters);

export const getActivity = (tenantID) =>
  http.get(`${baseUrl}/setting/activity/${tenantID}`);
export const putRunning = (tenantID) =>
  http.put(`${baseUrl}/setting/running/${tenantID}`);

export const getState = () => http.get(`${baseUrl}/address/state`);
export const getDistrict = (parameters) =>
  http.get(`${baseUrl}/address/district`, parameters);

export const getGeneralSettings = (parameters) =>
  http.get(`${baseUrl}/setting/general`, parameters);
export const createOrUpdateSettings = (parameters) =>
  http.post(`${baseUrl}/setting/createOrUpdate`, parameters);
export const getWhatsappSpendAnalysis = () =>
  http.get(`${baseUrl}/spend-analysis/`);
export const getDashboardStoreCount = (parameters) =>
  http.get(`${baseUrl}/dashboard/dashboard-store-count`, parameters);

export const getProductCount = (parameters) =>
  http.get(`${baseUrl}/dashboard/product-count`, parameters);

export const getOrderDetailsCount = (parameters) =>
  http.get(`${baseUrl}/dashboard/order-details-count`, parameters);

export const getPaymentToolsCount = (parameters) =>
  http.get(`${baseUrl}/dashboard/payment-tools-count`, parameters);

export const getWhatsappNotificationCount = (parameters) =>
  http.get(`${baseUrl}/dashboard/whatsapp-notification-count`, parameters);

// Subscription Plan URLS
export const getSubscriptionPlan = (parameters) =>
  http.post(`${baseUrl}/subscription-plan`, parameters);
export const createSubscriptionPlan = (parameters) =>
  http.post(`${baseUrl}/subscription-plan/create`, parameters);
export const deleteSubscriptionPlan = (id) =>
  http.del(`${baseUrl}/plan/delete-subscription/${id}`);

export const updateTenant = (parameters, tenantID) =>
  http.put(`${baseUrl}/tenant/tenant-update/${tenantID}`, parameters);

export const validateEmail = (parameters) =>
  http.post(`${baseUrl}/tenant/validate-email`, parameters);
export const insertTemporaryUser = (parameters) =>
  http.post(`${baseUrl}/tenant/create-temp-user`, parameters);
export const getBusinessCategory = (parameters) =>
  http.get(`${baseUrl}/tenant/business-category`, parameters);
export const createBusinessCategory = (parameters) =>
  http.post(`${baseUrl}/tenant/business-category`, parameters);
export const getPlanDuration = (parameters) =>
  http.get(`${baseUrl}/plan/duration`, parameters);
export const getActiveDuration = (parameters) =>
  http.post(`${baseUrl}/plan/active-duration`, parameters);
export const getSubscriptionPlans = (parameters) =>
  http.post(`${baseUrl}/plan/subscription`, parameters);
export const createPlanPurchase = (parameters) =>
  http.post(`${baseUrl}/tenant/plan-purchase`, parameters);
export const verifyPayment = (parameters) =>
  http.post(`${baseUrl}/tenant/rzp-payment-success`, parameters);
export const getPaymentData = (parameters) =>
  http.get(`${baseUrl}/tenant/payment`, parameters);
export const redirectAdminPanel = (parameters) =>
  http.post(`${baseUrl}/tenant/redirect-admin-panel`, parameters);
export const healthCheckUrl = (parameters) =>
  http.post(`${baseUrl}/tenant/url-health-check`, parameters);
export const addCoupon = (object, files) =>
  http.handleMultipart(`${baseUrl}/coupon/create`, object, files, 'POST');
export const getCouponData = (parameters) =>
  http.post(`${baseUrl}/coupon`, parameters);
export const getCouponPerformance = (parameters) =>
  http.post(`${baseUrl}/plan/coupon-performance`, parameters);
// Slider Template
export const getSliderData = (parameters) =>
  http.get(`${baseUrl}/slider-template`, parameters);
export const addSliderTemplate = (object, files) =>
  http.handleMultipart(`${baseUrl}/slider-template`, object, files, 'POST');
export const editSliderTemplate = (object, files) =>
  http.handleMultipart(
    `${baseUrl}/slider-template/update`,
    object,
    files,
    'POST'
  );
export const deleteSliderTemplate = (sliderId) =>
  http.del(`${baseUrl}/slider-template/delete/${sliderId}`);
export const updateStatus = (id, parameters) =>
  http.put(`${baseUrl}/slider-template/update-status/${id}`, parameters);
export const getPlanFeatures = (parameters) =>
  http.post(`${baseUrl}/plan/feature`, parameters);
export const createSubscriptionPlans = (parameters) =>
  http.post(`${baseUrl}/plan/create-subscription`, parameters);
export const createCustomSubscriptionPlans = (parameters) =>
  http.post(`${baseUrl}/plan/custom-create-subscriber`, parameters);
export const editSubscriptionPlans = (parameters) =>
  http.get(`${baseUrl}/plan/edit-subscription`, parameters);
export const updateSubscriptionPlans = (parameters) =>
  http.post(`${baseUrl}/plan/update-subscription`, parameters);
export const deleteCoupons = (parameters) =>
  http.del(`${baseUrl}/coupon/`, '', parameters);
// tenant-report
export const createOrUpdateWhatsappScheduler = (parameters) =>
  http.post(`${baseUrl}/tenant-report/createOrUpdate-scheduler`, parameters);
export const fetchScheduler = (parameters) =>
  http.get(`${baseUrl}/tenant-report/scheduler`, parameters);

// template
export const getTemplates = (parameters) =>
  http.get(`${baseUrl}/template/get-all-template`, parameters);
export const createApperanceTemplate = (parameters) =>
  http.get(`${baseUrl}/template/default/appearance`, parameters);

export const getAdminTrackerEvent = async (data, url) => {
  try {
    const {
      REACT_APP_EVENT_API_KEY,
      REACT_APP_KEY_NAME,
      REACT_APP_EVENT_TRACK_URL,
    } = process.env;
    if (!isEmpty(REACT_APP_EVENT_TRACK_URL)) {
      const config = {
        method: 'GET',
        headers: {
          apikey: REACT_APP_EVENT_API_KEY,
          keyname: REACT_APP_KEY_NAME,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      const apiURL = `${REACT_APP_EVENT_TRACK_URL}/${url}`;
      let response = [];
      await axios
        .get(apiURL, {
          params: data,
          headers: get(config, 'headers', {}),
        })
        .then((value) => {
          response = value;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
      return response;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
  return {};
};

export const getAdminEvents = async (data) => {
  const apiURL = 'event-tracker/admin-traker-events';
  const response = await getAdminTrackerEvent(data, apiURL);
  return response;
};
// FAQ
export const getFaqList = () => http.get(`${baseUrl}/faq/faq-list`);
export const getFaqListByCategoryId = (id) =>
  http.get(`${baseUrl}/faq/faq-list/${id}`);
export const getFaqCategoryList = (parameters) =>
  http.get(`${baseUrl}/faq/faq-category`, parameters);
export const deleteFaq = (id) => http.del(`${baseUrl}/faq/delete-faq/${id}`);
export const deleteFaqCategory = (id) =>
  http.del(`${baseUrl}/faq/delete-faq-category/${id}`);
export const updateFaq = (id, parameters) =>
  http.post(`${baseUrl}/faq/update-faq/${id}`, parameters);
export const updateFaqCategory = (id, parameters) =>
  http.post(`${baseUrl}/faq/update-faq-category/${id}`, parameters);
export const createNewFaq = (parameters) =>
  http.post(`${baseUrl}/faq/add-new-faq`, parameters);
export const createNewFaqCategory = (parameters) =>
  http.post(`${baseUrl}/faq/add-new-faq-category`, parameters);

// Blog
export const getAllBlogs = () => http.get(`${baseUrl}/blog/blog-list`);
export const getBlogById = (id) => http.get(`${baseUrl}/blog/${id}`);
export const deleteBlog = (parameters) =>
  http.del(`${baseUrl}/blog/delete-blog`, parameters);
export const createBlog = (object, files) =>
  http.handleMultipart(`${baseUrl}/blog/create-blog`, object, files, 'POST');
export const postBlog = (id, parameters) =>
  http.put(`${baseUrl}/blog/post-blog/${id}`, parameters);
export const getReportData = (parameters) =>
  http.get(`${baseUrl}/report/store-report-data`, parameters);
export const getOrderReportData = (parameters) =>
  http.get(`${baseUrl}/report/order-report-data`, parameters);
export const downloadReportData = (parameters) =>
  http.get(`${baseUrl}/report/customer-report-data`, parameters);
export const getTenantReportData = (parameters) =>
  http.get(`${baseUrl}/report/tenant-report-data`, parameters);
export const getSubscriptionHistoryData = (parameters) =>
  http.get(`${baseUrl}/subscription-plan/subscription-history`, parameters);

import 'react-phone-input-2/lib/style.css';
import React from 'react';

const LeftSideContent = ({ infoText }) => {
  return (
    <div className="left-side-content">
      <div className="left-side-content-text">{infoText?.leftSideText}</div>
    </div>
  );
};
export default LeftSideContent;

import { Col, Form, Input, Row, notification } from 'antd';
import 'react-phone-input-2/lib/style.css';
import React, { useEffect, useState } from 'react';
import { debounce, get } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  createPublicTenant,
  publicTenantUrlValidation,
} from '../../utils/api/url-helper';
import NextButton from './next-button';
import {
  BUSINESS_NAME_ALREADY_EXISTS,
  CLIC_TENANT_MODE,
  CUSTOMER_URL_ALREADY_EXISTS,
  ONBOARD_CATEGORY,
  ONBOARD_WEBSITE,
  SESSION_STORAGE_ACCOUNT_KEY,
  SESSION_STORAGE_CHOOSE_WEBSITE_KEY,
  SESSION_STORAGE_TENANT_DETAILS_KEY,
  SHOW_LAYOUT_STORE,
  STORE_NAME_ALREADY_EXISTS,
  STORE_URL_ALREADY_EXISTS,
} from '../../settings/constants';
import {
  removeAllSesstionStorage,
  removeSesstionStorage,
} from '../../settings/helper-function';

const StoreDetailsForm = () => {
  const [storeForm] = Form.useForm();
  const history = useHistory();
  const [validationError, setValidationError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [accountValue, setAccountValue] = useState({});
  const [tenantMode, setTenantMode] = useState('');

  useEffect(() => {
    try {
      const chooseWebsiteStorage = sessionStorage.getItem(
        SESSION_STORAGE_CHOOSE_WEBSITE_KEY
      );
      const accountFormStorage = sessionStorage.getItem(
        SESSION_STORAGE_ACCOUNT_KEY
      );
      const accountFormValues = JSON.parse(accountFormStorage);
      const chooseWebsiteValue = JSON.parse(chooseWebsiteStorage);
      setAccountValue(get(accountFormValues, 'account', {}));
      setTenantMode(get(chooseWebsiteValue, 'website', ''));
    } catch (error) {
      setAccountValue({});
      setTenantMode('');
    }
  }, []);

  const validTenantUrl = (value) => {
    if (value) {
      publicTenantUrlValidation({ url: value })
        .then(() => {
          setValidationError(false);
          return false;
        })
        .catch(() => {
          setValidationError(true);
          return true;
        });
    }
  };

  const handelNameOnchange = debounce((value) => {
    const identifier = value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    validTenantUrl(identifier);
  }, 500);

  const validateSpecialCharacters = (rule, value) => {
    const regex = /^([\w-]*)$/g;
    if (value && !regex.test(value)) {
      return Promise.reject(new Error('Special characters are not allowed'));
    }
    return Promise.resolve();
  };

  const createTenant = (value) => {
    const parameters = {
      tenant_name: get(accountValue, 'tenant_name', ''),
      phone_number: get(accountValue, 'phone_number', ''),
      country_code: get(accountValue, 'country_code', ''),
      email_address: get(accountValue, 'email_address', ''),
      password: get(accountValue, 'password', ''),
      business_name: get(value, 'business_name', ''),
      admin_url: `admin-${get(value, 'business_name', '')
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()}.zupain.com`,
      customer_url: `${get(value, 'business_name', '')
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()}.zupain.com`,
      tenant_mode: tenantMode,
    };
    setButtonLoading(true);
    createPublicTenant(parameters)
      .then((response) => {
        setButtonLoading(false);
        if (response?.success) {
          storeForm.resetFields();
          removeAllSesstionStorage();
          const tenantDetails = get(response, 'message', {});
          tenantDetails.password = get(accountValue, 'password', '');
          tenantDetails.admin_url = get(response, 'message.admin_url', '');
          sessionStorage.setItem(
            SESSION_STORAGE_TENANT_DETAILS_KEY,
            JSON.stringify({ tenant_details: { tenantDetails } })
          );
          history.push(ONBOARD_CATEGORY);
        }
      })
      .catch((error) => {
        setButtonLoading(false);
        if (get(error, 'error', '') === BUSINESS_NAME_ALREADY_EXISTS) {
          storeForm.setFields([
            {
              name: 'business_name',
              errors: [STORE_NAME_ALREADY_EXISTS],
            },
          ]);
        } else if (get(error, 'error', '') === CUSTOMER_URL_ALREADY_EXISTS) {
          storeForm.setFields([
            {
              name: 'customer_url',
              errors: [STORE_URL_ALREADY_EXISTS],
            },
          ]);
        } else {
          notification.error({
            message: get(error, 'error', false),
          });
        }
      });
  };

  const handlePreviousButton = () => {
    removeSesstionStorage(SESSION_STORAGE_CHOOSE_WEBSITE_KEY);
    history.push(ONBOARD_WEBSITE);
  };

  const handleContinueButton = () => {
    if (!validationError) {
      storeForm.validateFields().then((value) => {
        createTenant(value);
      });
    }
  };

  return (
    <div>
      <div className="mt-10 store-container slide-top">
        <Form form={storeForm} layout="vertical" name="store">
          <div className="mt-20">
            <Row>
              <Col span={24}>
                <Form.Item
                  size="large"
                  label={
                    tenantMode === CLIC_TENANT_MODE
                      ? 'What is your Website name'
                      : 'What is your Store name'
                  }
                  name="business_name"
                  validateStatus={validationError && 'error'}
                  hasFeedback={!validationError}
                  help={validationError ? 'store name already taken!' : null}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter store name',
                    },
                    { validator: validateSpecialCharacters },
                    {
                      validator: async (rule, value, callback) => {
                        const vaildURL = await validTenantUrl(value);
                        if (vaildURL) {
                          callback();
                        } else {
                          callback('Url already taken!');
                        }
                      },
                      validateTrigger: 'onChange',
                    },
                  ]}
                >
                  <Input
                    addonAfter=".zupain.com"
                    onChange={(event) => handelNameOnchange(event.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="right-side-container-next-button" id="bottom-button">
        <NextButton
          showLayout={SHOW_LAYOUT_STORE}
          handleContinueButton={handleContinueButton}
          handlePreviousButton={handlePreviousButton}
          loading={buttonLoading}
        />
      </div>
    </div>
  );
};
export default StoreDetailsForm;

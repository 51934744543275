import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import LoginButton from '../containers/tenant-create/login-button';
import AccountDetailsForm from '../containers/tenant-create/account-details-form';
import ChooseWebsiteOrStore from '../containers/tenant-create/choose-website-store';
import StoreDetailsForm from '../containers/tenant-create/store-details-form';
import CategoryList from '../containers/tenant-create/category-list';
import ChooseCategory from '../containers/tenant-create/choose-category';
import ChooseTemplate from '../containers/tenant-create/choose-template';
import OnboardBaseLayout from '../containers/layout/onboard-layout';
import NotFoundPage from '../containers/error-pages/error-404';
import {
  ONBOARD_ACCOUNT,
  ONBOARD_ADD_CATEGORY,
  ONBOARD_CATEGORY,
  ONBOARD_STORE,
  ONBOARD_TEMPLATE,
  ONBOARD_WEBSITE,
  ONBORAD_LOGIN,
  SESSION_STORAGE_CATGORY_KEY,
  SESSION_STORAGE_ACCOUNT_KEY,
  SESSION_STORAGE_CHOOSE_WEBSITE_KEY,
  SESSION_STORAGE_TENANT_DETAILS_KEY,
} from '../settings/constants';

function PrivateRoute({ component: Component, layout: AppLayout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(properties) => {
        return (
          <AppLayout {...properties}>
            <Component {...properties} />
          </AppLayout>
        );
      }}
    />
  );
}

const OnBoardRoutes = () => {
  const history = useHistory();

  const validateAccountPage = () => {
    const tenantMode = sessionStorage.getItem(
      SESSION_STORAGE_CHOOSE_WEBSITE_KEY
    );
    const accountValues = sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_KEY);
    const tenantDetailsValues = sessionStorage.getItem(
      SESSION_STORAGE_TENANT_DETAILS_KEY
    );
    const categoryValues = sessionStorage.getItem(SESSION_STORAGE_CATGORY_KEY);
    if (
      isEmpty(accountValues) &&
      isEmpty(tenantMode) &&
      isEmpty(tenantDetailsValues) &&
      history?.location?.pathname !== ONBOARD_ACCOUNT
    ) {
      history.push(ONBORAD_LOGIN);
    } else if (history?.location?.pathname === ONBOARD_ACCOUNT) {
      history.push(ONBOARD_ACCOUNT);
    } else if (!isEmpty(accountValues) && isEmpty(tenantMode)) {
      history.push(ONBOARD_WEBSITE);
    } else if (!isEmpty(accountValues) && !isEmpty(tenantMode)) {
      history.push(ONBOARD_STORE);
    } else if (!isEmpty(tenantDetailsValues) && isEmpty(categoryValues)) {
      history.push(ONBOARD_CATEGORY);
    } else if (!isEmpty(categoryValues)) {
      history.push(ONBOARD_TEMPLATE);
    } else {
      history.push(ONBORAD_LOGIN);
    }
  };

  useEffect(() => {
    validateAccountPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const onBackButtonEvent = (event) => {
    event.preventDefault();
    window.history.forward();
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route exact path={ONBORAD_LOGIN} component={LoginButton} />
      <PrivateRoute
        exact
        path={ONBOARD_ACCOUNT}
        component={AccountDetailsForm}
        layout={OnboardBaseLayout}
      />
      <PrivateRoute
        exact
        path={ONBOARD_WEBSITE}
        component={ChooseWebsiteOrStore}
        layout={OnboardBaseLayout}
      />
      <PrivateRoute
        exact
        path={ONBOARD_STORE}
        component={StoreDetailsForm}
        layout={OnboardBaseLayout}
      />
      <PrivateRoute
        exact
        path={ONBOARD_CATEGORY}
        component={CategoryList}
        layout={OnboardBaseLayout}
      />
      <PrivateRoute
        exact
        path={ONBOARD_ADD_CATEGORY}
        component={ChooseCategory}
        layout={OnboardBaseLayout}
      />
      <Route exact path={ONBOARD_TEMPLATE} component={ChooseTemplate} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};
export default OnBoardRoutes;

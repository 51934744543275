import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import LeftSideContent from '../tenant-create/left-side.js';
import {
  ACCOUNT_INFO_SUB_TITLE,
  ACCOUNT_INFO_TITLE,
  CATEGORY_INFO_SUB_TITLE,
  CATEGORY_INFO_TITLE,
  CHOOSE_CATEGORY_INFO_SUB_TITLE,
  CHOOSE_CATEGORY_INFO_TITLE,
  CHOOSE_WEBSITE_INFO_SUB_TITLE,
  CHOOSE_WEBSITE_INFO_TITLE,
  LEFT_SIDE_ACCOUNT_TEXT,
  LEFT_SIDE_CATEGORY_TEXT,
  LEFT_SIDE_CHOOSE_CATEGORY_TEXT,
  LEFT_SIDE_CHOOSE_WEBSITE_TEXT,
  LEFT_SIDE_STORE_TEXT,
  ONBOARD_ACCOUNT,
  ONBOARD_ADD_CATEGORY,
  ONBOARD_CATEGORY,
  ONBOARD_STORE,
  ONBOARD_TEMPLATE,
  ONBOARD_WEBSITE,
  SHOW_LAYOUT_ACCOUNT,
  SHOW_LAYOUT_CATEGORY,
  SHOW_LAYOUT_CHOOSE_CATEGORY,
  SHOW_LAYOUT_CHOOSE_TEMPLATE,
  SHOW_LAYOUT_CHOOSE_WEBSITE_STORE,
  SHOW_LAYOUT_LOGIN,
  SHOW_LAYOUT_STORE,
  STORE_INFO_SUB_TITLE,
  STORE_INFO_TITLE,
} from '../../settings/constants';
import Logo from '../tenant-create/logo';
import TermAndConditions from '../tenant-create/term-and-conditions';
import RightSideText from '../tenant-create/right-side/right-side-text';
import { IsMobileView } from '../../settings/helper-function';

const OnboardBaseLayout = (properties) => {
  const history = useHistory();
  const location = useLocation();
  const [infoText, setInfoText] = useState({
    title: ACCOUNT_INFO_TITLE,
    subTitle: ACCOUNT_INFO_SUB_TITLE,
    leftSideText: LEFT_SIDE_ACCOUNT_TEXT,
  });
  const [showLayout, setShowLayout] = useState(SHOW_LAYOUT_LOGIN);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (history?.location?.pathname) {
      switch (history?.location?.pathname) {
        case ONBOARD_ACCOUNT:
          setInfoText({
            title: ACCOUNT_INFO_TITLE,
            subTitle: ACCOUNT_INFO_SUB_TITLE,
            leftSideText: LEFT_SIDE_ACCOUNT_TEXT,
          });
          setShowLayout(SHOW_LAYOUT_ACCOUNT);
          break;
        case ONBOARD_WEBSITE:
          setInfoText({
            title: CHOOSE_WEBSITE_INFO_TITLE,
            subTitle: CHOOSE_WEBSITE_INFO_SUB_TITLE,
            leftSideText: LEFT_SIDE_CHOOSE_WEBSITE_TEXT,
          });
          setShowLayout(SHOW_LAYOUT_CHOOSE_WEBSITE_STORE);
          break;
        case ONBOARD_STORE:
          setInfoText({
            title: STORE_INFO_TITLE,
            subTitle: STORE_INFO_SUB_TITLE,
            leftSideText: LEFT_SIDE_STORE_TEXT,
          });
          setShowLayout(SHOW_LAYOUT_STORE);
          break;
        case ONBOARD_CATEGORY:
          setInfoText({
            title: CATEGORY_INFO_TITLE,
            subTitle: CATEGORY_INFO_SUB_TITLE,
            leftSideText: LEFT_SIDE_CATEGORY_TEXT,
          });
          setShowLayout(SHOW_LAYOUT_CATEGORY);
          break;
        case ONBOARD_ADD_CATEGORY:
          setInfoText({
            title: CHOOSE_CATEGORY_INFO_TITLE,
            subTitle: CHOOSE_CATEGORY_INFO_SUB_TITLE,
            leftSideText: LEFT_SIDE_CHOOSE_CATEGORY_TEXT,
          });
          setShowLayout(SHOW_LAYOUT_CHOOSE_CATEGORY);
          break;
        case ONBOARD_TEMPLATE:
          setInfoText({
            title: CHOOSE_CATEGORY_INFO_TITLE,
            subTitle: CHOOSE_CATEGORY_INFO_SUB_TITLE,
            leftSideText: LEFT_SIDE_CHOOSE_CATEGORY_TEXT,
          });
          setShowLayout(SHOW_LAYOUT_CHOOSE_TEMPLATE);
          break;
        default:
          setInfoText({
            title: ACCOUNT_INFO_TITLE,
            subTitle: ACCOUNT_INFO_SUB_TITLE,
            leftSideText: LEFT_SIDE_ACCOUNT_TEXT,
          });
          setShowLayout(SHOW_LAYOUT_LOGIN);
          break;
      }
    }
  }, [history, location]);

  useEffect(() => {
    setMobileView(IsMobileView());
  }, []);

  const handlecontentClassName = () => {
    if (showLayout === SHOW_LAYOUT_CATEGORY) {
      return 'right-side-container-category-content';
    }
    if (showLayout === SHOW_LAYOUT_CHOOSE_WEBSITE_STORE) {
      return 'right-side-container-website-store-content';
    }
    return 'right-side-container-content';
  };

  return (
    <Layout className="flex-row">
      <div className={mobileView ? 'mobileview-bg-container' : 'w-100'}>
        {mobileView && <div className="color-block" />}
        <div className={mobileView ? 'mobileview-bg-shadow' : 'flex'}>
          {!mobileView && (
            <div className="left-div-bg-styles">
              <LeftSideContent infoText={infoText} />
            </div>
          )}
          <div className="right-side-container">
            <div className="right-side-container-logo flex-center">
              <Logo showLayout={showLayout} />
            </div>
            <div className="right-side-container-info-text flex-center">
              <RightSideText infoText={infoText} />
            </div>
            <div className={handlecontentClassName()}>
              {properties.children}
            </div>
            {(showLayout === SHOW_LAYOUT_LOGIN ||
              showLayout === SHOW_LAYOUT_ACCOUNT) && (
              <div className="right-side-container-terms">
                <TermAndConditions />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OnboardBaseLayout;

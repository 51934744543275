import 'react-phone-input-2/lib/style.css';
import React from 'react';

const TermAndConditions = () => {
  const { REACT_APP_ZUPAIN_PRIVACY_URL } = process.env;
  return (
    <div className="term-and-conditions">
      <span>
        By signing up, you agree to our{' '}
        <a
          href={`${REACT_APP_ZUPAIN_PRIVACY_URL}/terms-and-conditions`}
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href={`${REACT_APP_ZUPAIN_PRIVACY_URL}/privacy-policy`}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </span>
    </div>
  );
};
export default TermAndConditions;

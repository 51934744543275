import React, { useEffect } from "react";
import { get } from "lodash";
import { ReactComponent as GoogleLogo } from "../../assets/images/google-logo.svg";

export default function GoogleUsers(properties) {
  const { handleLoginScreen } = properties;
  const { REACT_APP_GOOGLE_API } = process.env;

  const fetchGoogleUser = (response) => {
    const userName = get(response, "name", "");
    const userMail = get(response, "email", "");
    const data = {
      tenant_name: userName,
      email_address: userMail,
    };
    handleLoginScreen(data);
  };

  const base64UrlDecode = (base64Url) => {
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const paddedBase64 =
      base64 + "==".substring(0, (4 - (base64.length % 4)) % 4);
    return atob(paddedBase64);
  };
  const decodeJwtResponse = (jwt) => {
    const payload = jwt.split(".");
    const decodedPayload = base64UrlDecode(payload[1]);
    return JSON.parse(decodedPayload);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  window.handlelogin = (response) => {
    const responsePayload = decodeJwtResponse(get(response, "credential", ""));
    fetchGoogleUser(responsePayload);
  };

 
  useEffect(() => {
    const googleBtn = document.getElementById("custom-google-btn");
    googleBtn?.addEventListener("click", () => {
      document.querySelector(".g_id_signin div[role=button]").click();
    });
    return () => {
      googleBtn.current?.removeEventListener("click", handleClick);
    };
  })

  return (
    <>
      <div
        id="g_id_onload"
        data-client_id={REACT_APP_GOOGLE_API}
        data-context="signup"
        data-ux_mode="popup"
        data-callback="handlelogin"
        data-nonce=""
        data-auto_prompt="false"
      />

      <div className="g_id_signin" />
      <button id="custom-google-btn" className="googlelogin-btn">
        <GoogleLogo className="google-icon" />
        <span className="google-text">Sign in with Google</span>
      </button>
    </>
  );
}

import 'react-phone-input-2/lib/style.css';
import React from 'react';
import { useHistory } from 'react-router-dom';
import GoogleUsers from './google-oauth';
// import { ReactComponent as FacebookIcon } from '../../assets/images/facebook_icon.svg';
// import { ReactComponent as InstagramIcon } from '../../assets/images/instagram_icon.svg';
import { ReactComponent as EmailLogo } from '../../assets/images/email-logo.svg';
import TermAndConditions from './term-and-conditions';
import Logo from './logo';
import {
  ONBOARD_ACCOUNT,
  SESSION_STORAGE_SOCIAL_LOGIN_KEY,
} from '../../settings/constants';

const LoginButton = (properties) => {
  const { mobileView } = properties;
  const history = useHistory();

  const handleLoginScreen = (data) => {
    sessionStorage.setItem(
      SESSION_STORAGE_SOCIAL_LOGIN_KEY,
      JSON.stringify({ login: data })
    );
    history.push(ONBOARD_ACCOUNT);
  };

  return (
    <div className="new-login-page-container">
      <div className="color-block" />
      <div
        className="login-page-details-container"
        style={{
          top: mobileView ? '53%' : '50%',
        }}
      >
        <div className="tenant-title-container">
          <Logo />
        </div>
        <div className="new-button-container">
          <div className="google-comps mt-20">
            <GoogleUsers handleLoginScreen={handleLoginScreen} />
          </div>
          {/* <div className="google-comps mt-20">
            <button
              onClick={() => handleLoginScreen({})}
              type="button"
              className="googlelogin-btn"
            >
              <FacebookIcon className="google-icon" />
              <span className="google-text">Sign in with Facebook</span>
            </button>
          </div>
          <div className="google-comps mt-20">
            <button
              onClick={() => handleLoginScreen({})}
              type="button"
              className="googlelogin-btn"
            >
              <InstagramIcon className="google-icon" />
              <span className="google-text">Sign in with Instagram</span>
            </button>
          </div> */}
          <div className="google-comps mt-20">
            <button
              onClick={() => {
                history.push('/onboard/account');
              }}
              type="button"
              className="googlelogin-btn"
            >
              <EmailLogo className="google-icon" />
              <span className="google-text">Sign in with Email address</span>
            </button>
          </div>
        </div>
        <div className="term-and-conditions-container">
          <TermAndConditions />
        </div>
      </div>
    </div>
  );
};
export default LoginButton;

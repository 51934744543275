import 'react-phone-input-2/lib/style.css';
import React, { useEffect } from 'react';
import { animationClassLeftRight } from '../../../settings/helper-function';

const RightSideText = (properties) => {
  const { infoText } = properties;
  useEffect(() => {
    animationClassLeftRight('right-text');
  }, [infoText]);
  return (
    <div className="info-text-container" id="right-text">
      <div className="info-text-container-title">{infoText?.title}</div>
      <div className="info-text-container-sub-title">{infoText?.subTitle}</div>
    </div>
  );
};
export default RightSideText;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ApplicationRoutes from "./router/application-routes";
import OnBoardRoutes from "./router/onboard-routes";
import { ConfigProvider } from "antd";

const AppRoutes = () => {
  const [onboardRouter, setOnboardRouter] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname.includes("/onboard")
    ) {
      // history.push(ONBORAD_LOGIN);
      setOnboardRouter(true);
    } else {
      setOnboardRouter(false);
    }
  }, [history, history.location.pathname]);
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
              colorPrimary: '#001529',
              colorLink: '#1eaa7d',
              colorSuccess: '#52c41a',
              colorWarning: '#faad14',
              colorError: '#FF0000',
              colorBorder: '#1eaa7d!',
              boxShadow: 'none !important',
              fontFamily: `'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
          'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
          },
        }}
      >
        {!onboardRouter && <ApplicationRoutes />}
        {onboardRouter && <OnBoardRoutes />}
      </ConfigProvider>
    </>
  );
};
export default AppRoutes;

import React from 'react';
import Verification from './verification';

function OtpVerification(properties) {
  const {
    showOtp,
    form,
    code,
    mobileView,
    inputReference,
    setShowOtp,
    setShowLayout,
    handleResendOtp,
    setLoginPage,
    loginPage,
    userPhone,
    accountValue,
    formuse,
    verifyScreen,
    setVerifyScreen,
    errorText,
    setErrorText,
    handleVerifyToAccountPage,
  } = properties;

  return (
    <div className="otp-verfication-mob">
      <div className="otp-header-div">
        <span className="otp-heading">Code was sent to</span>
        <br />
        <span className="otp-mob">
          {`${userPhone?.countryCode}${userPhone?.phone}`}
        </span>
        <br />
        <div
          className="otp-ahref"
          style={{ color: '#472DC5' }}
          onClick={handleVerifyToAccountPage}
          role="presentation"
        >
          Not your Number?
        </div>
      </div>
      <Verification
        form={form}
        code={code}
        mobileView={mobileView}
        inputRef={inputReference}
        setShowOtp={setShowOtp}
        showOtp={showOtp}
        accountValue={accountValue}
        setShowLayout={setShowLayout}
        handleResendOtp={handleResendOtp}
        setLoginPage={setLoginPage}
        loginPage={loginPage}
        formuse={formuse}
        verifyScreen={verifyScreen}
        setVerifyScreen={setVerifyScreen}
        errorText={errorText}
        setErrorText={setErrorText}
      />
    </div>
  );
}

export default OtpVerification;

import { Card, Col, Row } from 'antd';
import React from 'react';
import { get } from 'lodash';

const TemplateCard = (properties) => {
  const { templates, handleModelOpen } = properties;
  const renderImage = (data) => {
    if (get(data, 'column[0].section.contentType', '') === 'image') {
      return get(data, 'column[0].section.section_image_content.Location', '');
    }
    return get(data, 'column[0].section.sectionArray[0].banner.img', '');
  };

  return (
    <Row className="template-card-row">
      {templates.map((data) => {
        return (
          <Col
            key={get(data, 'template_uid', '')}
            xs={24}
            sm={24}
            md={6}
            lg={8}
            xl={8}
          >
            <Card
              hoverable
              className="tempalte-card-main slide-right-template"
              onClick={() => handleModelOpen(data)}
            >
              <img
                src={renderImage(get(data, 'template_value[0]', ''))}
                alt="template"
              />
            </Card>
            <p>{get(data, 'template_name', '')}</p>
          </Col>
        );
      })}
    </Row>
  );
};

export default TemplateCard;

import { Col, Input, Row, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { get, map } from 'lodash';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { useHistory } from 'react-router-dom';
import {
  createBusinessCategory,
  getBusinessCategory,
} from '../../utils/api/url-helper';
import {
  ONBOARD_CATEGORY,
  ONBOARD_TEMPLATE,
  SESSION_STORAGE_CATGORY_KEY,
  SHOW_LAYOUT_CHOOSE_CATEGORY,
} from '../../settings/constants';
import { IsMobileView } from '../../settings/helper-function';
import NextButton from './next-button';

function ChooseCategory() {
  const history = useHistory();

  const [mobileView, setMobileView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  const fetchCategories = () => {
    setLoading(true);
    const parameters = {
      fav_category: 0,
    };
    getBusinessCategory(parameters)
      .then((response) => {
        setCategories(get(response, 'data', []));
        setLoading(false);
      })
      .catch((error) => {
        notification.error({
          message: get(error, 'error', false),
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    setMobileView(IsMobileView());
    fetchCategories();
  }, []);

  const handleOnChange = (event) => {
    setCategoryName(event.target.value);
  };

  const handleChange = (value) => {
    sessionStorage.setItem(
      SESSION_STORAGE_CATGORY_KEY,
      JSON.stringify({ category_uid: value })
    );
    history.push(ONBOARD_TEMPLATE);
  };

  const createCategory = () => {
    if (categoryName) {
      setButtonLoading(true);
      const parameters = {
        category_name: categoryName,
      };
      createBusinessCategory(parameters)
        .then((response) => {
          handleChange(get(response, 'data.category_uid', ''));
        })
        .catch((error) => {
          notification.error({
            message: get(error, 'error', false),
          });
          setButtonLoading(false);
        });
    }
  };

  const handlePreviousButton = () => {
    history.push(ONBOARD_CATEGORY);
  };

  const handleContinueButton = () => {
    createCategory();
  };

  return (
    <Spin spinning={loading}>
      <div className="choose-category-main-layout">
        <div>
          <Input onChange={(event) => handleOnChange(event)} />
        </div>
        {!mobileView && (
          <div className="choose-category-main-layout-list-container">
            <Row>
              {map(categories, (data) => (
                <Col className="category-item-container">
                  <CheckableTag
                    key={get(data, 'category_uid')}
                    onChange={() => handleChange(get(data, 'category_uid'))}
                  >
                    {get(data, 'category_name', '')}
                  </CheckableTag>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
      <div className="right-side-container-next-button" id="bottom-button">
        <NextButton
          showLayout={SHOW_LAYOUT_CHOOSE_CATEGORY}
          handleContinueButton={handleContinueButton}
          handlePreviousButton={handlePreviousButton}
          loading={buttonLoading}
        />
      </div>
    </Spin>
  );
}

export default ChooseCategory;

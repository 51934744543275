import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
  USERINFO,
  TENANTINFO,
  SETTINGS,
  FORGETPASSWORD,
  GENERATEPASSWORD,
  PERMISSIONERROR,
  TENANTLIST,
  TENANTCUSTOMERS,
  TENANTDASHBOARD,
  TENANTSTORES,
  TENANTEDIT,
  TENANT_SERVICE_SECRETS,
  TENANT_CP_SECRETS,
  TENANT_B2C_SECRETS,
  TENANT_SECRETS,
  TENANT_ACTIVITY,
  ALL_ORDERS,
  GENERALSETTINGS,
  SPENDANALYSIS,
  ADMIN_INITIAL_SCREEN,
  DASHBOARD,
  CLIC,
  D2C,
  ADMINUSING,
  FAQ,
  BLOG,
  BLOGDETAILS,
  CHATSUPPORT,
  REPORT,
  SUBSCRIPTION_HISTORY,
} from '../settings/constants';
import '../style/zp-theme/index.less';
import 'font-awesome/css/font-awesome.min.css';
import AuthProvider, { AuthContext } from '../context/auth';
import permissionError from '../containers/error-pages/error-403';
import Loader from '../components/inline-loader';

const NotFoundPage = lazy(() => import('../containers/error-pages/error-404'));
const DashboardLayout = lazy(() =>
  import('../containers/dashboard-management')
);
const UserLayout = lazy(() => import('../containers/user-management'));
const TenantLayout = lazy(() => import('../containers/tenant-management'));
const SettingLayout = lazy(() => import('../containers/settings-manangement'));
const OrdersLayout = lazy(() => import('../containers/orders-management'));
const TenantDashboard = lazy(() =>
  import('../containers/tenant-management/dashboard')
);
const TenantStores = lazy(() =>
  import('../containers/tenant-management/store-component/stores')
);
const TenantCustomers = lazy(() =>
  import('../containers/tenant-management/customer-component/customers')
);
const TenantEdits = lazy(() =>
  import('../containers/tenant-management/edit-component/edit')
);
const TenantDetails = lazy(() =>
  import('../containers/tenant-management/details')
);
const TenantSecret = lazy(() =>
  import('../containers/tenant-management/secret')
);
const TenantServiceSecret = lazy(() =>
  import('../containers/tenant-management/secret-component/service')
);
const TenantB2CSecret = lazy(() =>
  import(
    '../containers/tenant-management/secret-component/bussiness-to-customer'
  )
);
const TenantCpSecret = lazy(() =>
  import('../containers/tenant-management/secret-component/control-panel')
);
const TenantActivity = lazy(() =>
  import('../containers/tenant-management/activity-component/activity')
);
const GeneratePassword = lazy(() =>
  import('../containers/authentication/generate-password')
);
const ForgetPassword = lazy(() =>
  import('../containers/authentication/forgot-password')
);
const Login = lazy(() =>
  import('../containers/authentication/login-container')
);
const AdminLayout = lazy(() => import('../containers/layout/layout'));
const GeneralSettings = lazy(() => import('../containers/general-settings'));
const SpendAnalysis = lazy(() => import('../containers/spend-analysis'));

const SubscriptionLayout = lazy(() =>
  import('../containers/subscription-management/new-subscription')
);

const adminEvent = lazy(() => import('../containers/admin-event'));
const AdminConsoleLayout = lazy(() =>
  import('../containers/admin-console-management')
);
const BlogLayout = lazy(() =>
  import('../containers/admin-console-management/blog')
);

const BlogDetails = lazy(() =>
  import('../containers/admin-console-management/blog-details')
);

const chatSupport = lazy(() =>
  import('../containers/chat-support')
);

const report = lazy(() =>
  import('../containers/report/report')
);
const subscriptionHistory = lazy(() =>
  import('../containers/subscription-management/subscription-history/index')
);

function PrivateRoute({ component: Component, layout: AppLayout, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(properties) => {
        if (!localStorage.getItem('token') && !isAuthenticated)
          history.push('/');
        return (
          <AppLayout {...properties}>
            <Component {...properties} />
          </AppLayout>
        );
      }}
    />
  );
}

const ApplicationRoutes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PrivateRoute
            exact
            path={DASHBOARD}
            component={DashboardLayout}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={USERINFO}
            component={UserLayout}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={TENANTLIST}
            component={TenantLayout}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={SETTINGS}
            component={SettingLayout}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={ALL_ORDERS}
            component={OrdersLayout}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={TENANT_SERVICE_SECRETS}
            component={TenantServiceSecret}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={TENANT_B2C_SECRETS}
            component={TenantB2CSecret}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={TENANT_CP_SECRETS}
            component={TenantCpSecret}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={TENANT_SECRETS}
            component={TenantSecret}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={TENANT_ACTIVITY}
            component={TenantActivity}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={TENANTINFO}
            component={TenantDetails}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={TENANTEDIT}
            component={TenantEdits}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={TENANTDASHBOARD}
            component={TenantDashboard}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={TENANTSTORES}
            component={TenantStores}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={TENANTCUSTOMERS}
            component={TenantCustomers}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={GENERALSETTINGS}
            component={GeneralSettings}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={SPENDANALYSIS}
            component={SpendAnalysis}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={CLIC}
            component={SubscriptionLayout}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={D2C}
            component={SubscriptionLayout}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={ADMINUSING}
            component={adminEvent}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={REPORT}
            component={report}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={FAQ}
            component={AdminConsoleLayout}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={BLOG}
            component={BlogLayout}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={BLOGDETAILS}
            component={BlogDetails}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={CHATSUPPORT}
            component={chatSupport}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={SUBSCRIPTION_HISTORY}
            component={subscriptionHistory}
            layout={AdminLayout}
          />
          <Route path={FORGETPASSWORD}>
            <Suspense fallback={<Loader />}>
              <ForgetPassword />
            </Suspense>
          </Route>
          <Route path={GENERATEPASSWORD}>
            <Suspense fallback={<Loader />}>
              <GeneratePassword />
            </Suspense>
          </Route>
          <Route
            exact
            path={ADMIN_INITIAL_SCREEN}
            render={(properties) => (
              <Suspense fallback={<Loader />}>
                <Login {...properties} />
              </Suspense>
            )}
          />
          <Route path={PERMISSIONERROR} component={permissionError} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};
export default ApplicationRoutes;

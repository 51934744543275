import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Layout,
  Menu,
  Modal,
  Row,
  Space,
  Spin,
  Tabs,
  notification,
} from 'antd';
import { get, map } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import TemplateHeader from './template-header';
import {
  CART_PAGE_TEXT,
  CREATING_YOUR_SITE_TEXT,
  FAILED_TO_LOAD,
  HOME_PAGE_TEXT,
  PAGE_TAB_VALUES,
  PRODUCT_DETAILS_PAGE_TEXT,
  PRODUCT_PAGE_TEXT,
  SESSION_STORAGE_CATGORY_KEY,
  SESSION_STORAGE_TENANT_DETAILS_KEY,
  TEMPLATE_INFO_SUB_TITLE,
  TEMPLATE_INFO_SUB_TITLE_MOBILE,
  TEMPLATE_INFO_TITLE,
  TEMPLATE_SLUG_APPEARANCE,
  ZUPAIN_LOGO,
} from '../../../settings/constants';
import TemplateCard from './template-card';
import {
  createApperanceTemplate,
  getTemplates,
  healthCheckUrl,
  redirectAdminPanel,
} from '../../../utils/api/url-helper';
import {
  IsMobileView,
  errorNotification,
  removeAllSesstionStorage,
} from '../../../settings/helper-function';
import { ReactComponent as MobileIcon } from '../../../assets/images/mobile-icon.svg';

const { Sider, Content } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [getItem('All Template', 'temp', null, null, null)];

const ChooseTemplate = () => {
  const [infoText] = useState({
    title: TEMPLATE_INFO_TITLE,
    subTitle: TEMPLATE_INFO_SUB_TITLE,
    mobilesubTitle: TEMPLATE_INFO_SUB_TITLE_MOBILE,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalMobile, setIsModalMobile] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [loading, setLoading] = useState({});
  const [activeTab, setActiveTab] = useState('Home');
  const [count, setCount] = useState(0);
  const [loadVisible, setLoadVisible] = useState(false);
  const [tenantDetails, setTenantDetails] = useState({});
  const [categoryId, setCategoryId] = useState({});
  const [mobileView, setMobileView] = useState(false);

  let intervalId = '';

  const fetchData = () => {
    const parameters = {
      slug: TEMPLATE_SLUG_APPEARANCE,
    };
    setLoading(true);
    getTemplates(parameters)
      .then((response) => {
        setTemplates(get(response, 'data', []));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorNotification(get(error, 'error', false) || FAILED_TO_LOAD);
      });
  };

  useEffect(() => {
    setMobileView(IsMobileView());
    try {
      const tenantDetailsStorage = sessionStorage.getItem(
        SESSION_STORAGE_TENANT_DETAILS_KEY
      );
      const categoryStorage = sessionStorage.getItem(
        SESSION_STORAGE_CATGORY_KEY
      );
      const categoryValues = JSON.parse(categoryStorage);
      const tenantDetailsValues = JSON.parse(tenantDetailsStorage);
      setCategoryId(get(categoryValues, 'category_uid', {}));
      setTenantDetails(
        get(tenantDetailsValues, 'tenant_details.tenantDetails', {})
      );
    } catch (error) {
      setCategoryId('');
      setTenantDetails({});
    }
    fetchData();
  }, []);

  const handleChangeBanner = (value) => {
    if (get(value, 'section.contentType', '') === 'banner') {
      return (
        <Row>
          <Col>
            <img
              src={get(value, 'section.sectionArray[0].banner.img', '')}
              alt="frame"
            />
          </Col>
        </Row>
      );
    }
    if (get(value, 'section.contentType', '') === 'image') {
      return (
        <img
          src={get(value, 'section.section_image_content.Location', '')}
          alt="frame"
        />
      );
    }
    if (get(value, 'section.contentType', '') === 'video') {
      return (
        <video
          width="100%"
          height="100%"
          kind="captions"
          className="video-src"
          preload="auto"
          autoPlay
          controls
        >
          <track kind="captions" />
          <source
            kind="captions"
            src={get(value, 'section.section_video_content.Location', '')}
            type="video/mp4"
          />
        </video>
      );
    }
    if (get(value, 'section.contentType', '') === 'product') {
      return (
        <Row>
          <Col>
            <img src={get(value, 'section.product_image', '')} alt="frame" />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleModelOpen = (value) => {
    setSelectedTemplate(value);
    setActiveTab(HOME_PAGE_TEXT);
    setIsModalOpen(true);
  };

  const startTimerCount = () => {
    setCount(count + 1);
    setLoadVisible(true);
  };

  const redirectToAdminPanel = (url = '') => {
    const parameters = {
      tenant_url: url || get(tenantDetails, 'admin_url', ''),
      email_address: get(tenantDetails, 'email_address', ''),
      password: get(tenantDetails, 'password', ''),
    };
    redirectAdminPanel(parameters)
      .then((response) => {
        setLoadVisible(false);
        return window.open(get(response, 'data', ''), '_blank');
      })
      .catch((error) => {
        notification.error({
          message: get(error, 'error', false),
        });
      });
  };

  const healthCheckUrlApi = (url) => {
    const parameters = {
      tenant_url: url,
    };
    healthCheckUrl(parameters)
      .then(() => {
        clearInterval(intervalId);
        redirectToAdminPanel(url || get(tenantDetails, 'admin_url', ''));
      })
      .catch(() => {
        startTimerCount();
      });
  };

  useEffect(() => {
    if (count <= 0) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    intervalId = setTimeout(() => {
      healthCheckUrlApi(get(tenantDetails, 'admin_url', ''));
    }, 5000);
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalId);
    };
  }, [count]);

  const createApperanceTemplates = (template) => {
    if (template) {
      setLoading(true);
      const parameters = {
        template_uid: get(template, 'template_uid', ''),
        tenant_uid: get(tenantDetails, 'tenant_uid', ''),
        category_uid: categoryId,
      };
      createApperanceTemplate(parameters)
        .then(() => {
          setLoading(false);
          removeAllSesstionStorage();
          startTimerCount();
        })
        .catch((error) => {
          notification.error({
            message: get(error, 'error', false),
          });
          setLoading(false);
        });
    }
  };

  const handleUseTemplate = (value) => {
    // switch (activeTab) {
    //   case HOME_PAGE_TEXT:
    //     setActiveTab(PRODUCT_PAGE_TEXT);
    //     break;
    //   case PRODUCT_PAGE_TEXT:
    //     setActiveTab(PRODUCT_DETAILS_PAGE_TEXT);
    //     break;
    //   case PRODUCT_DETAILS_PAGE_TEXT:
    //     setActiveTab(CART_PAGE_TEXT);
    //     break;
    //   default:
    //     setIsModalOpen(false);
    //     createApperanceTemplates(value);
    // }
    setIsModalOpen(false);
    createApperanceTemplates(value);
  };
  const handleOkMobile = () => {
    setIsModalMobile(false);
  };

  const handleModelMobile = () => {
    setIsModalMobile(true);
  };

  const handleActiveTab = (value) => {
    setActiveTab(value);
  };

  return (
    <Spin spinning={loading}>
      <div className="template-styles-main">
        <Space
          direction="vertical"
          style={{
            width: '100%',
          }}
        >
          <Layout>
            <Sider className="slider-styles">
              <div className="template-logo-div">
                <span className="template-logo">Zupain</span>
              </div>
              <div className="side-menu-div">
                <Menu items={items} defaultSelectedKeys={['temp']} />
              </div>
            </Sider>
            <Layout>
              <Content className="content-styles">
                <div>
                  <div className="zupain-logo-styles">
                    <img src={ZUPAIN_LOGO} alt="test" className="logo" />
                  </div>
                  <div className="template-header">
                    <TemplateHeader infoText={infoText} />
                  </div>
                  <Row className="breadcrum-styles">
                    <Col span={8} className="heading">
                      All template
                    </Col>
                    <Col span={16} className="description">
                      Tap to Preview Template
                    </Col>
                  </Row>

                  <div className="template-card">
                    <TemplateCard
                      templates={templates}
                      handleModelOpen={handleModelOpen}
                    />
                  </div>
                </div>
              </Content>
            </Layout>
          </Layout>
        </Space>
      </div>
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        footer={null}
        closable={false}
        width={mobileView ? '95%' : '80%'}
        centered
        className="template-modal"
      >
        <Row>
          <div className="dummy-template-header-container">
            <Tabs
              type="card"
              activeKey={activeTab}
              className="theme-tabs"
              onChange={handleActiveTab}
              items={map(PAGE_TAB_VALUES, (tab) => {
                return {
                  label: <span className="flex-center">{tab?.lable}</span>,
                  key: tab?.value,
                };
              })}
            />
          </div>
        </Row>
        <div>
          {activeTab === HOME_PAGE_TEXT &&
            map(selectedTemplate?.template_value, (value) => (
              <Row>
                {map(value?.column, (column) => (
                  <Col span={get(column, 'span', 24)}>
                    {handleChangeBanner(column)}
                  </Col>
                ))}
              </Row>
            ))}
          {activeTab === PRODUCT_PAGE_TEXT && (
            <img
              src={get(selectedTemplate, 'page_value.product_image', '')}
              alt="frame"
            />
          )}
          {activeTab === PRODUCT_DETAILS_PAGE_TEXT && (
            <img
              src={get(
                selectedTemplate,
                'page_value.product_details_image',
                ''
              )}
              alt="frame"
            />
          )}
          {activeTab === CART_PAGE_TEXT && (
            <img
              src={get(selectedTemplate, 'page_value.cart_image', '')}
              alt="frame"
            />
          )}
        </div>
        <div className="template-button-div">
          <Row
            style={{ width: activeTab === HOME_PAGE_TEXT ? '97%' : '98%' }}
            justify="space-between"
          >
            <Col className="col-left">
              {activeTab === HOME_PAGE_TEXT && (
                <Button
                  icon={<MobileIcon />}
                  className="btn-show"
                  onClick={() => handleModelMobile()}
                >
                  Show Mobile View
                </Button>
              )}
            </Col>
            <Col className="col-right">
              <Button
                onClick={() => handleUseTemplate(selectedTemplate)}
                type="primary"
                className="btn-use"
              >
                {/* {activeTab === CART_PAGE_TEXT ? 'Use Template' : 'Next'} */}
                Use Template
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        visible={isModalMobile}
        onOk={handleOkMobile}
        onCancel={handleOkMobile}
        footer={null}
        zIndex={999999}
        className="template-modal-mobile"
        centered
        width="auto"
      >
        <div className="device">
          <div className="floating">
            <div className="camera">
              <div className="blob white" />
              <div className="blob bbg" />
              <div className="blob green" />
              <div className="blob red" />
            </div>
          </div>
          <div>
            {map(selectedTemplate?.template_value, (value) => (
              <Row>
                {map(value?.column, (column) => (
                  <Col span={get(column, 'span', 24)}>
                    {handleChangeBanner(column)}
                  </Col>
                ))}
              </Row>
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        footer={null}
        visible={loadVisible}
        maskClosable={false}
        className="connect-sales-modal"
        onCancel={() => {
          setLoadVisible(false);
        }}
      >
        <div className="modal-content-details">
          <Row align="middle">
            <div className="modal-title-text mr-30">
              {CREATING_YOUR_SITE_TEXT}
            </div>
            <Spin indicator={<LoadingOutlined />} />
          </Row>
        </div>
      </Modal>
    </Spin>
  );
};
export default ChooseTemplate;

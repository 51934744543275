// **************** ROUTE CONSTANT START **************************
// General Page Section
import WebsitePage from '../assets/images/website-page.svg';
import OnlinePage from '../assets/images/online-page.svg';
import { get } from 'lodash';

export const DASHBOARD = '/zp-admin/dashboard';
export const USERINFO = '/zp-admin/user-info';
export const TENANTLIST = '/zp-admin/tenant-info';
export const TENANTINFO = '/zp-admin/tenant-info/:tenantID';
export const TENANTDASHBOARD = '/zp-admin/tenant-info/dashboard/:tenantID';
export const TENANTSTORES = '/zp-admin/tenant-info/stores/:tenantID';
export const TENANTCUSTOMERS = '/zp-admin/tenant-info/customers/:tenantID';
export const TENANTSETTINGS = '/zp-admin/tenant-info/setting/:tenantID';
export const TENANT_SECRETS = '/zp-admin/tenant-info/secret/:tenantID';
export const TENANT_SERVICE_SECRETS =
  '/zp-admin/tenant-info/secret/service/:tenantID';
export const TENANT_B2C_SECRETS = '/zp-admin/tenant-info/secret/b2c/:tenantID';
export const TENANT_CP_SECRETS = '/zp-admin/tenant-info/secret/cp/:tenantID';
export const TENANT_ACTIVITY = '/zp-admin/tenant-info/activity/:tenantID';

export const TENANTEDIT = '/zp-admin/tenant-info/edit/:tenantID';
export const SETTINGS = '/zp-admin/settings';
export const ALL_ORDERS = '/zp-admin/order-details';
export const FORGETPASSWORD = '/zp-admin/forgot-password';
export const GENERATEPASSWORD = '/zp-admin/GeneratePassword/:token';
export const PERMISSIONERROR = '/zp-admin/permission-error';
export const LOGIN = '/zp-admin/login';
export const LOGOUT = '/zp-admin/logout';
export const ADMIN_INITIAL_SCREEN = '/zp-admin';
export const GENERALSETTINGS = '/zp-admin/general-settings';
export const SPENDANALYSIS = '/zp-admin/spend-analysis';
export const SUBSCRIPTION_PLAN = '/zp-admin/subscription-plan';
export const CLIC = '/zp-admin/subscription-plan/clic';
export const D2C = '/zp-admin/subscription-plan/d2c';
export const ONBORAD_LOGIN = '/onboard/login';
export const ONBOARD_ACCOUNT = '/onboard/account';
export const ONBOARD_WEBSITE = '/onboard/website';
export const ONBOARD_STORE = '/onboard/store';
export const ONBOARD_CATEGORY = '/onboard/category';
export const ONBOARD_ADD_CATEGORY = '/onboard/category/add';
export const ONBOARD_TEMPLATE = '/onboard/template';
export const ONBOARD_VERIFY_OTP = '/onboard/verify/otp';
export const ADMINUSING = '/zp-admin/admin-usage';
export const FAQ = '/zp-admin/admin-console/faq';
export const BLOG = '/zp-admin/admin-console/blog';
export const BLOGDETAILS = '/zp-admin/admin-console/blog/:blogID';
export const CHATSUPPORT = '/zp-admin/chat-support';
export const REPORT = '/zp-admin/report';
export const SUBSCRIPTION_HISTORY = '/zp-admin/subscription-history';

// **************** ROUTE CONSTANT END **************************

export const SESSION_STORAGE_SOCIAL_LOGIN_KEY = 'SOCIAL LOGIN';
export const SESSION_STORAGE_ACCOUNT_KEY = 'ACCOUNT';
export const SESSION_STORAGE_CHOOSE_WEBSITE_KEY = 'CHOOSE WEBSITE';
export const SESSION_STORAGE_STORE_KEY = 'STORE';
export const SESSION_STORAGE_TENANT_DETAILS_KEY = 'TENANT DETAILS';
export const SESSION_STORAGE_CATGORY_KEY = 'CATEGORY';

export const CURRENCY = '$';
export const CURRENCY_TYPE = 'INR';
export const CURRENCY_LANGUAGE = 'en-IN';
//* **************Notification Sections******************* */

// user message
export const USER_ADD_SUCCESS = 'User Created successfully';
export const USER_ADD_FAILED = 'Failed to create the user';
export const USER_UPDATE_SUCCESS = 'User Updated successfully';
export const USER_UPDATE_FAILED = 'Failed to update the user';
export const USER_DELETE_SUCCESS = 'User Deleted successfully';
export const USER_DELETE_FAILED = 'Failed to delete the user';
export const USER_UPDATE_PASSWORD_FAILED = 'Failed to update the password';
export const USER_UPDATE_PASSWORD_RESPONSE_SENT =
  'We have e-mailed your password reset link!';
export const USER_UPDATE_PASSWORD_SUCCESS = 'Your password has been reset!';

// config message
export const CONFIG_ADD_SUCCESS = 'Config Created successfully';
export const CONFIG_ADD_FAILED = 'Failed to create the Config';
export const CONFIG_UPDATE_SUCCESS = 'Config Updated successfully';
export const CONFIG_DELETE_SUCCESS = 'Config Deleted successfully';

// General error
export const FAILED_TO_LOAD = ' Failed to load the data';
export const DOWNLOAD_FAILED =
  'The download was unable to complete.Please try again later.';
// Tenant message
export const TENANT_ADD_SUCCESS = 'Tenant Created successfully';
export const TENANT_ADD_FAILED = 'Failed to create the tenant';
export const TENANT_UPDATE_SUCCESS = 'Tenant updated successfully';
export const TENANT_UPDATE_FAILED = 'Failed to update the tenant';
export const TENANT_DELETE_SUCCESS = 'Tenant Deleted successfully';
export const TENANT_DELETE_FAILED = 'Failed to delete the tenant';
export const TENANT_CAMPAIGN_FAILED = 'Failed to update the tenant campaign';
export const TENANT_CAMPAIGN_ACTIVATE =
  'Tenant Campaign activated  successfully';
export const TENANT_CAMPAIGN_DEACTIVATE =
  'Tenant Campaign deactivated successfully';
export const TENANT_WHATSAPP_ACTIVATE =
  'Tenant Whatsapp activated  successfully';
export const TENANT_WHATSAPP_DEACTIVATE =
  'Tenant Whatsapp deactivated successfully';
export const COSTING_ADD_SUCCESS = 'Costing Created successfully';
export const COSTING_UPDATE_SUCCESS = 'Costing updated successfully';

export const TENANT_MODE_LIST = [
  { value: 'Clic', label: 'Clic' },
  { value: 'Normal', label: 'Normal' },
];
export const DEFAULT_TENANT_MODE = 'Normal';
export const CLIC_TENANT_MODE = 'Clic';

// backgrund color code
export const BACKGROUND_COLOR = [
  '#F7B924',
  '#A0F725',
  '#25F78B',
  '#25BFF7',
  '#2556F7',
  '#A025F7',
  '#F72572',
  '#F7B924',
  '#A0F725',
  '#25F78B',
  '#25BFF7',
  '#2556F7',
  '#A025F7',
  '#F72572',
  '#A0F725',
  '#F7B924',
  '#25F78B',
  '#25BFF7',
  '#2530F7',
  '#A025F7',
  '#F72572',
];

// Date format
export const DATE_FORMAT = 'MMM DD, YYYY, hh:mm:s';
// create tenant
export const SHOW_LAYOUT_CHOOSE_TEMPLATE = 'Template';
export const SHOW_LAYOUT_CHOOSE_WEBSITE_STORE = 'Choose website or store';
export const FROM_SCREEN_CHOOSE_PLAN = 'CHOOSE_PLAN';
export const SHOW_LAYOUT_LOGIN = 'Login';
export const SHOW_LAYOUT_ACCOUNT = 'Account';
export const SHOW_LAYOUT_CATEGORY = 'Category';
export const SHOW_LAYOUT_CHOOSE_CATEGORY = 'Choose Category';
export const SHOW_LAYOUT_STORE = 'Store';
export const SHOW_LAYOUT_PLAN = 'Plan';
export const SHOW_LAYOUT_PAYMENT = 'Payment';
export const FEATURES_TITLE = 'Features';
export const LIFE_TIME_TEXT = 'Life Time';
export const PURCHASE_MODE_ONLINE = 'ONLINE';
export const PURCHASE_MODE_FREE = 'FREE';
export const PURCHASE_MODE_CONTACT_SALES = 'CONTACT_SALES';
export const EMAIL_ALREADY_EXISTS = 'Email already exists';
export const BUSINESS_NAME_ALREADY_EXISTS = 'Business name already exists';
export const CUSTOMER_URL_ALREADY_EXISTS = 'customer url already exist';
export const CATEGORY_ERROR_MESSAGE = 'Please select category to continue';
export const PLAN_ERROR_MESSAGE = 'Please select plan to continue';
export const PHONENUMBER_ERROR_MESSAGE = 'Please Enter valid Mobile Number';
export const STORE_NAME_ALREADY_EXISTS = 'Store name already exist';
export const STORE_URL_ALREADY_EXISTS = 'Store url already exist';
export const CREATING_YOUR_SITE_TEXT = 'Creating your site...';
export const CHOOSE_YOUR_PLAN_LIST = [
  {
    title: 'Website',
    description:
      'Recommended for individuals and businesses focused on promoting services',
    details: [
      'Stunning Website',
      'Booking Appointment',
      'Enquiry Form',
      'Social CRM with chat',
      'Google analytics',
      'Adobe Express for design',
    ],
    img: WebsitePage,
    mode: CLIC_TENANT_MODE,
  },
  {
    title: 'Online Store',
    description: 'Recommended for businesses that want to sell products online',
    details: [
      'Payment Integrations',
      'Shipment Integration',
      'Inventory Management',
      'Order Management',
      'Analytics and Reports',
      'Secure Checkout Process',
    ],
    img: OnlinePage,
    mode: DEFAULT_TENANT_MODE,
  },
];
// logo
export const ZUPAIN_LOGO =
  'https://images-zupain.s3.ap-south-1.amazonaws.com/zupain-logo.png';

export const ACCOUNT_INFO_TITLE = 'Welcome,  First thing first!';
export const ACCOUNT_INFO_SUB_TITLE = 'You can always change later';
export const LEFT_SIDE_ACCOUNT_TEXT =
  'Baby steps – let’s go ahead with your first step in this digital journey';
export const STORE_INFO_TITLE = 'Let’s set up a home for you';
export const STORE_INFO_SUB_TITLE = 'You can always create another later';
export const LEFT_SIDE_STORE_TEXT =
  'There are 3 million e-commerce brands in the world, it’s time to RSVP your +1';
export const CATEGORY_INFO_TITLE = 'Choose a category';
export const CATEGORY_INFO_SUB_TITLE =
  'This helps us to provide you better templates';
export const LEFT_SIDE_CATEGORY_TEXT =
  ' Every category is different – If you can find yours, don’t worry, you can add your own!';
export const CHOOSE_WEBSITE_INFO_TITLE =
  'Explore the Perfect Fit for Your Vision!';
export const CHOOSE_WEBSITE_INFO_SUB_TITLE =
  'This helps us identify what you want to accomplish';
export const LEFT_SIDE_CHOOSE_WEBSITE_TEXT =
  'Moment of truth – there are 1.13 billion Websites and 24 million of those are Online Stores';
export const CHOOSE_CATEGORY_INFO_TITLE = 'Choose a category';
export const CHOOSE_CATEGORY_INFO_SUB_TITLE =
  'This helps us to provide you better templates';
export const LEFT_SIDE_CHOOSE_CATEGORY_TEXT =
  'Out of the box? We love working with trendsetters!';
export const INCORRECT_CODE = 'Incorrect OTP. Please Re-Enter OTP';
export const OTP_LIMIT_EXCEED = 'Too many attempts. Please try again later';
export const INVALID_PHONE_NUMBER = 'Invaild phone number';
export const SOMETHING_ERROR = 'Something went wrong. Please try again later';
export const TEMPLATE_INFO_TITLE = 'Choose your template';
export const TEMPLATE_INFO_SUB_TITLE =
  'Select your ideal template and personalize your website effortlessly';
export const TEMPLATE_INFO_SUB_TITLE_MOBILE = 'This reduces your work load';

// default templates
export const TEMPLATE_SLUG_APPEARANCE = 'appearance';

// new subscrition
export const ADD_PLAN = 'Add Plan';
export const EDIT_PLAN = 'Edit Plan';
export const ADD_COUPON = 'Add Coupon';
export const COUPON = 'Coupon';
export const PLAN_ADDED = 'Plan Created successfully';
export const PLAN_UPDATED = 'Plan Updated successfully';
export const PLAN_HAS_BEEN_DELETED = 'Plan has been deleted';

// Coupon
export const COUPON_ADD_SUCCESS = 'Coupon Created successfully';
export const COUPON_ADD_FAILED = 'Failed to create the coupon';
export const COUPON_UPDATE_SUCCESS = 'Coupon Updated successfully';
export const COUPON_UPDATE_FAILED = 'Failed to update the coupon';
export const COUPON_DELETE_SUCCESS = 'Coupon Deleted successfully';
export const COUPON_DELETE_FAILED = 'Failed to delete the coupon';
export const COUPON_ALREADY_EXIST = 'Coupon already exists';
export const MAXIMUM_DISCOUNT_ERROR =
  'By value should also be greater than the Discount amount';
export const DISCOUNT_OFFER_ERROR =
  'Discount offer should be greater than the Maximum discount Amount';
export const ACTIVE_DURATION_FAILED = 'Failed active duration';
export const COUPON_FILTER = [
  { value: 'All' },
  { value: 'Active' },
  { value: 'Inactive' },
];
// Slider Template
export const TEMPLATE_ADD_SUCCESS = 'Template Created successfully';
export const TEMPLATE_ADD_FAILED = 'Failed to create the Template';
export const TEMPLATE_UPDATE_SUCCESS = 'Template Updated successfully';
export const TEMPLATE_UPDATE_FAILED = 'Failed to update the Template';
export const TEMPLATE_DELETE_SUCCESS = 'Template Deleted successfully';
export const TEMPLATE_DELETE_FAILED = 'Failed to delete the Template';

export const IS_JPG_OR_PNG_OR_WEBP = 'You can only upload JPG/PNG/WEBP file!';
export const PRODUCT_MEDIA_COUNT = 7;

// clic
export const CLIC_UPLOAD_FILE_FORM_PRODUCT = 'PRODUCT';
export const CLIC_UPLOAD_FILE_FORM_SETTINGS = 'SETTINGS';
export const NORMAL_UPLOAD_FILE = 'NORMALTENANT';
export const HOME_PAGE_TEXT = 'Home';
export const PRODUCT_PAGE_TEXT = 'Product';
export const PRODUCT_DETAILS_PAGE_TEXT = 'Product Details';
export const CART_PAGE_TEXT = 'Cart';
export const PAGE_TAB_VALUES = [
  {
    value: HOME_PAGE_TEXT,
    lable: HOME_PAGE_TEXT,
  },
  // {
  //   lable: PRODUCT_PAGE_TEXT,
  //   value: PRODUCT_PAGE_TEXT,
  // },
  // {
  //   lable: PRODUCT_DETAILS_PAGE_TEXT,
  //   value: PRODUCT_DETAILS_PAGE_TEXT,
  // },
  // {
  //   lable: CART_PAGE_TEXT,
  //   value: CART_PAGE_TEXT,
  // },
];

export const INITIAL_PAGE = 1;
export const CUSTOM = 'customize';
export const PAGE_LIMIT = 10;

export const selectOptionData = [
  {
    value: 'yester_day',
    count: 1,
    timePeriod: 'ordered_hour',
    label: 'Yesterday',
  },
  {
    value: 'today',
    count: 1,
    timePeriod: 'ordered_hour',
    label: 'Today',
  },
  {
    value: 'last_week',
    count: 7,
    timePeriod: 'ordered_day',
    label: 'Last Week',
  },
  {
    value: 'last_month',
    count: 30,
    timePeriod: 'ordered_day',
    label: 'Last Month',
  },
  {
    value: 'last_year',
    count: 365,
    timePeriod: 'ordered_month',
    label: 'Last Year',
  },
  {
    value: 'customize',
    label: 'Customize',
  },
];
export const subscriptionDateOption = [
  {
    value: 15,
    label: '15 Days',
  },
  {
    value: 30,
    label: '1 Month',
  },
  {
    value: 90,
    label: '3 Months',
  },
  {
    value: 180,
    label: '6 Months',
  },
  {
    value: 365,
    label: '1 Year',
  },
  {
    value: 'customize',
    label: 'Customize',
  },
];

export const SELECT_EVENT = [
  {
    label: 'All Event',
    value: '',
  },
  {
    label: 'Admin login',
    value: 'ADMIN_CLICK',
  },
  {
    label: 'Orders',
    value: 'ORDERS_CLICK',
  },
  {
    label: 'Products',
    value: 'PRODUCTS_CLICK',
  },
  {
    label: 'Stores',
    value: 'STORES_CLICK',
  },
  {
    label: 'Social Leads',
    value: 'SOCIAL_LEADS_CLICK',
  },
  {
    label: 'Page Builder',
    value: 'PAGE_BUILDER_CLICK',
  },
];

export const storeColumns = [
  {
      title: "Store Name",
      dataIndex: "store_name",
      key: "store_name",
      ellipsis: false
  },
  {
      title: "Store Location",
      dataIndex: "store_location",
      key: "store_location",
      ellipsis: true,
  },
  {
      title: "customer Url",
      dataIndex: "customer_url",
      key: "customer_url",
      ellipsis: true,
      render: (text, record) => record.tenant_data[0]?.customer_url
  },
  {
      title: "Customer Phone",
      dataIndex: "phone_number",
      key: "tenant_name",
      ellipsis: false,
      render: (text, record) => record.tenant_data[0]?.phone_number
  },
  {
    title: "Customer Email",
    dataIndex: "email_address",
    key: "tenant_name",
    ellipsis: false,
    render: (text, record) => record?.tenant_data[0]?.email_address
}
];

export const customerColumns = [
  {
      title: "Customer Name",
      dataIndex: "tenant_name",
      key: "tenant_name",
      ellipsis: false,
      render: (text, record) => record?.tenant_data[0]?.tenant_name

  },
  {
      title: "Customer Url",
      dataIndex: "customer_url",
      key: "customer_url",
      ellipsis: true,
      render: (text, record) => record?.tenant_data[0]?.customer_url

  },
  {
      title: "Number of Visit",
      dataIndex: "visit_count",
      key: "visit_count",
      ellipsis: true,
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.visit_count - b.visit_count,
  },
  {
      title: "Time of visit",
      dataIndex: "time_of_visit",
      key: "time_of_visit",
      ellipsis: false
  }
];

export const tenantColumns = [
 
  {
    title: "Customer Name",
    dataIndex: "user_name",
    key: "user_name",
    ellipsis: false
},
{
    title: "Customer Url",
    dataIndex: "url",
    key: "url",
    ellipsis: true,
},
{
    title: "Number of Visit",
    dataIndex: "visit_count",
    key: "visit_count",
    ellipsis: true,
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.visit_count - b.visit_count,
},
{
    title: "Time of visit",
    dataIndex: "time_of_visit",
    key: "time_of_visit",
    ellipsis: false
}
];

export const orderColumns = [
  {
      title: "Order Count",
      dataIndex: "total_orders",
      key: "total_orders",
      ellipsis: false
  },
  {
      title: "Order Revenue",
      dataIndex: "total_price_sum",
      key: "total_price_sum",
      ellipsis: true,
  },
  {
      title: "customer Url",
      dataIndex: "customer_url",
      key: "customer_url",
      ellipsis: true,
      render: (text, record) => get(record,'tenant_data[0].customer_url', '')
  },
  {
      title: "Customer  name",
      dataIndex: "store_name",
      key: "store_name",
      ellipsis: false,
      render: (text, record) => get(record,'tenant_data[0].tenant_name', '')

  }
];

export const INVALID_DATE = 'Invalid date';

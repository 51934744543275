import React from 'react';

const TemplateHeader = (properties) => {
  const { infoText } = properties;
  return (
    <div className="info-text-template">
      <div className="info-text-template-title">{infoText?.title}</div>
      <div className="info-text-template-sub-title">{infoText?.subTitle}</div>
      <div className="info-text-template-sub-title-mob">
        {infoText?.mobilesubTitle}
      </div>
    </div>
  );
};
export default TemplateHeader;

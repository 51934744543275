import { Button, Card, Col, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { get, map } from 'lodash';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  CHOOSE_YOUR_PLAN_LIST,
  DEFAULT_TENANT_MODE,
  ONBOARD_STORE,
  SESSION_STORAGE_CHOOSE_WEBSITE_KEY,
} from '../../settings/constants';
import { IsMobileView } from '../../settings/helper-function';
import { ReactComponent as PlansIcon } from '../../assets/images/plans_icon.svg';
import { ReactComponent as TickIcon } from '../../assets/images/tick_icon.svg';

const ChooseWebsiteOrStore = () => {
  const history = useHistory();
  const [mobileView, setMobileView] = useState(false);
  const [selectedmode, setSelectedMode] = useState('');
  const [initialArrow, setInitialArrow] = useState(false);
  const [initialIndex, setInitialIndex] = useState();

  useEffect(() => {
    setMobileView(IsMobileView);
  }, []);

  const handleRadioButton = (event) => {
    setSelectedMode(event.target.value);
  };

  const handleClickArrow = (type, index, event) => {
    event.stopPropagation();
    if (type === 'close') {
      setInitialArrow(true);
      setInitialIndex(index);
    } else {
      setInitialArrow(false);
    }
  };

  const handleSubscribeButton = (mode) => {
    sessionStorage.setItem(
      SESSION_STORAGE_CHOOSE_WEBSITE_KEY,
      JSON.stringify({ website: mode })
    );
    history.push(ONBOARD_STORE);
  };

  return (
    <div className="choose-plan-container">
      <div className="choose-plan-title-container">
        <div className="choose-plan-title">What are you looking for?</div>
        <div className="choose-plan-list-container slide-left">
          <div className="choose-plan-row-container">
            {map(CHOOSE_YOUR_PLAN_LIST, (data, index) => {
              return (
                <div
                  className={`choose-plan-card-container ${
                    data?.title === DEFAULT_TENANT_MODE && mobileView
                      ? 'zupain-card-border'
                      : 'clic-card-border'
                  }`}
                >
                  <Card
                    hoverable
                    className={
                      initialArrow && index === initialIndex
                        ? 'card-styles-left'
                        : 'card-styles-right'
                    }
                  >
                    {!mobileView ? (
                      <div
                        role="presentation"
                        onClick={() => handleSubscribeButton(data?.mode)}
                      >
                        <Row className="flex-end learn-more-row">
                          {initialArrow && index === initialIndex ? (
                            <Col
                              onClick={(event) =>
                                handleClickArrow('open', index, event)
                              }
                            >
                              <div className="flex mt-10">
                                <div className="learn-more">Hide</div>
                                <div>
                                  <DoubleLeftOutlined
                                    style={{ color: '#472DC5' }}
                                  />
                                </div>
                              </div>
                            </Col>
                          ) : (
                            <Col
                              onClick={(event) =>
                                handleClickArrow('close', index, event)
                              }
                            >
                              <div className="flex mt-10">
                                <div className="learn-more">Learn more</div>
                                <div>
                                  <DoubleRightOutlined
                                    style={{ color: '#472DC5' }}
                                  />
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col
                            span={
                              initialArrow && index === initialIndex ? 12 : 24
                            }
                          >
                            <div className="title-zupain">
                              <PlansIcon />
                              <span className="theme-color">{data?.title}</span>
                            </div>
                            <div className="align-center">
                              <span className="title-description">
                                {data?.description}
                              </span>
                            </div>
                            <div style={{ overflow: 'hidden' }}>
                              <img
                                style={
                                  initialArrow && index === initialIndex
                                    ? { width: 'auto' }
                                    : { width: '320px' }
                                }
                                src={get(data, 'img', '')}
                                alt=""
                              />
                            </div>
                          </Col>
                          <Col
                            span={
                              initialArrow && index === initialIndex ? 12 : 0
                            }
                          >
                            <div className="choose-details-list">
                              {map(data.details, (item) => {
                                return (
                                  <div className="choose-details-list-item">
                                    <div>
                                      <TickIcon />
                                    </div>
                                    <div className="info-text ml-10">
                                      {item}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="subscribe-button-container-inside">
                              <Button
                                onClick={() =>
                                  handleSubscribeButton(data?.mode)
                                }
                              >
                                Get started for free
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div className="choose-details-container">
                        <Row>
                          <Col
                            span={
                              initialArrow && index === initialIndex ? 24 : 24
                            }
                            onClick={(event) =>
                              handleClickArrow('close', index, event)
                            }
                          >
                            <div className="flex-end">
                              <Radio
                                checked={
                                  selectedmode === get(data, 'title', '')
                                }
                                value={get(data, 'title', '')}
                                onChange={handleRadioButton}
                                onClick={() =>
                                  handleSubscribeButton(data?.mode)
                                }
                              />
                            </div>
                            <Row>
                              <Col span={12}>
                                <div className="title-zupain">
                                  <PlansIcon className="plan-icon" />
                                  <span className="theme-color">
                                    {data?.title}
                                  </span>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                  <span className="title-description">
                                    {data?.description}
                                  </span>
                                </div>
                              </Col>
                              <Col span={12}>
                                <div style={{ overflow: 'hidden' }}>
                                  <img
                                    style={
                                      initialArrow && index === initialIndex
                                        ? { width: '100px', height: 'auto' }
                                        : { width: '144px', height: '120px' }
                                    }
                                    src={get(data, 'img', '')}
                                    alt=""
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            span={
                              initialArrow && index === initialIndex ? 24 : 0
                            }
                            onClick={(event) =>
                              handleClickArrow('open', index, event)
                            }
                          >
                            <Row className="choose-details-list-main">
                              <Col className="choose-details-list" span={12}>
                                {map(data.details.slice(0, 3), (item) => {
                                  return (
                                    <div className="choose-details-list-item">
                                      <div style={{ marginTop: '6px' }}>
                                        <TickIcon />
                                      </div>
                                      <div className="info-text">{item}</div>
                                    </div>
                                  );
                                })}
                              </Col>
                              <Col className="choose-details-list" span={12}>
                                {map(data.details.slice(3), (item) => {
                                  return (
                                    <div className="choose-details-list-item">
                                      <div style={{ marginTop: '6px' }}>
                                        <TickIcon />
                                      </div>
                                      <div className="info-text">{item}</div>
                                    </div>
                                  );
                                })}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChooseWebsiteOrStore;

import { Col, Form, Input, Row, Spin, notification } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import React, { useEffect, useState, useRef } from 'react';
import './index.less';
import { LoadingOutlined } from '@ant-design/icons';
import { get, map, toLower } from 'lodash';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useHistory } from 'react-router-dom';
import getFormItemRules, {
  IsMobileView,
  firebaseInitial,
  handleOtpCode,
  removeSesstionStorage,
  validatePhoneNumber,
} from '../../settings/helper-function';
import Verification from './verification';
import OtpVerifyButton from './otp-verify-buuton';
import NextButton from './next-button';
import {
  EMAIL_ALREADY_EXISTS,
  ONBOARD_WEBSITE,
  ONBORAD_LOGIN,
  SESSION_STORAGE_ACCOUNT_KEY,
  SESSION_STORAGE_SOCIAL_LOGIN_KEY,
  SHOW_LAYOUT_ACCOUNT,
} from '../../settings/constants';
import { insertTemporaryUser, validateEmail } from '../../utils/api/url-helper';
import { continueEvent } from '../../components/facebook-pixel-events';
import OtpVerification from './otp-verify';

const AccountDetailsForm = () => {
  const [accountForm] = Form.useForm();
  const [formuse] = Form.useForm();

  const inputReference = useRef(null);
  const history = useHistory();
  const [mobileView, setMobileView] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showOtp, setShowOtp] = useState({
    otp: false,
    loader: false,
    verified: false,
  });
  const [otpLoading, setOtpLoading] = useState(false);
  const [formAnimation, setFormAnimation] = useState(false);
  const [isError, setIsError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [errorText, setErrorText] = useState('');
  const [userPhone, setUserPhone] = useState({});
  const [code, setCode] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [verifyScreen, setVerifyScreen] = useState(false);
  const [loginPage, setLoginPage] = useState(true);
  const [accountValue, setAccountValue] = useState({});

  const validateAccountPage = () => {
    const socialLoginStorage = sessionStorage.getItem(
      SESSION_STORAGE_SOCIAL_LOGIN_KEY
    );
    try {
      const socialLoginValue = JSON.parse(socialLoginStorage);
      accountForm.setFields([
        {
          name: 'email_address',
          value: get(socialLoginValue, 'login.email_address', ''),
        },
        {
          name: 'tenant_name',
          value: get(socialLoginValue, 'login.tenant_name', ''),
        },
      ]);
    } catch (error) {
      accountForm.setFields([]);
    }
  };

  useEffect(() => {
    setMobileView(IsMobileView());
    firebaseInitial();
    validateAccountPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const phoneColumnSpan = () => {
    if (showOtp.otp) {
      return 12;
    }
    if (showOtp.loader || showOtp.verified) {
      return 16;
    }
    if (!showOtp.otp && otpLoading) {
      return 12;
    }
    return 24;
  };

  useEffect(() => {
    if (formAnimation === true && !mobileView) {
      document.getElementById('bottom').classList.add('slide-bottom');
    }
  }, [formAnimation, mobileView]);

  const handleFieldChange = () => {
    const formError = accountForm.getFieldsError();
    map(formError, (value) => {
      switch (value.name[0]) {
        case 'tenant_name':
          if (value.errors.length > 0) {
            setNameError(true);
          } else {
            setNameError(false);
          }
          break;
        case 'phone_number':
          if (value.errors.length > 0) {
            setPhoneError(true);
          } else {
            setPhoneError(false);
          }
          break;
        case 'email_address':
          if (value.errors.length > 0) {
            setEmailError(true);
          } else {
            setEmailError(false);
          }
          break;
        case 'password':
          if (value.errors.length > 0) {
            setPasswordError(true);
          } else {
            setPasswordError(false);
          }
          break;
        default:
          setEmailError(false);
          setNameError(false);
          setPasswordError(false);
          setPhoneError(false);
          break;
      }
    });
  };

  const handleOtp = (data, recaptchaId) => {
    handleOtpCode(
      data,
      recaptchaId,
      inputReference,
      setCode,
      setShowOtp,
      setButtonLoading,
      mobileView,
      setVerifyScreen,
      setOtpLoading
    );
  };

  const onChangePhoneNumber = (phone, data) => {
    setIsError(true);
    setCountryCode(get(data, 'dialCode', ''));
    setPhoneNumber(phone);
    setErrorText('');
    if (isValidPhoneNumber(`+${phone}`) === true && !mobileView) {
      setUserPhone({
        phone: Number(phone.slice(get(data, 'dialCode', '').length)),
        countryCode: `+${Number(get(data, 'dialCode', ''))}`,
      });
      handleOtp(
        {
          phone: Number(phone.slice(get(data, 'dialCode', '').length)),
          countryCode: `+${Number(get(data, 'dialCode', ''))}`,
        },
        'signing-button'
      );
    } else {
      formuse.resetFields();
      setOtpLoading(false);
      setShowOtp({ otp: false, loader: false, verified: false });
    }
  };

  const handlePreviousButton = () => {
    removeSesstionStorage(SESSION_STORAGE_SOCIAL_LOGIN_KEY);
    history.push(ONBORAD_LOGIN);
  };

  const createTemporaryUser = (value) => {
    const parameters = {
      email_address: get(value, 'email_address', ''),
      name: get(value, 'tenant_name', ''),
      phone_number: phoneNumber.slice(countryCode.length),
      country_code: `+${countryCode}`,
    };
    insertTemporaryUser(parameters)
      .then(() => {
        setFormAnimation(true);
        setButtonLoading(false);
        if (
          isValidPhoneNumber(`+${value.phone_number}`) === true &&
          mobileView
        ) {
          setUserPhone({
            phone: Number(value.phone_number.slice(countryCode.length)),
            countryCode: `+${Number(countryCode)}`,
          });
          handleOtp(
            {
              phone: Number(value.phone_number.slice(countryCode.length)),
              countryCode: `+${Number(countryCode)}`,
            },
            'signing-button'
          );
          value.phone_number = value.phone_number.slice(countryCode.length);
          value.country_code = `+${countryCode}`;
          setAccountValue(value);
        } else {
          setShowOtp({ otp: false, loader: false, verified: false });
          value.phone_number = value.phone_number.slice(countryCode.length);
          value.country_code = `+${countryCode}`;
          sessionStorage.setItem(
            SESSION_STORAGE_ACCOUNT_KEY,
            JSON.stringify({ account: value })
          );
          history.push(ONBOARD_WEBSITE);
        }
      })
      .catch((error) => {
        setButtonLoading(false);
        notification.error({
          message: get(error, 'error', false),
        });
      });
  };

  const validateEmailID = (value) => {
    const parameters = {
      email_address: get(value, 'email_address', ''),
    };
    setButtonLoading(true);
    validateEmail(parameters)
      .then(() => {
        createTemporaryUser(value);
      })
      .catch((error) => {
        setButtonLoading(false);
        if (get(error, 'error', '') === EMAIL_ALREADY_EXISTS) {
          accountForm.setFields([
            {
              name: 'email_address',
              errors: [EMAIL_ALREADY_EXISTS],
            },
          ]);
        } else {
          notification.error({
            message: get(error, 'error', false),
          });
        }
      });
  };

  const handleContinueButton = () => {
    accountForm.validateFields().then((items) => {
      continueEvent('CreateAccount');
      validateEmailID(items);
    });
  };

  const handleResendOtp = () => {
    setLoginPage(true);
    handleOtp(userPhone, 'signing-button');
    formuse.resetFields();
    setErrorText('');
  };

  const handleVerifyToAccountPage = () => {
    setErrorText('');
    formuse.resetFields();
    setShowOtp({
      otp: false,
      loader: false,
      verified: false,
    });
    setVerifyScreen(false);
  };

  return (
    <div>
      <div id="signing-button" />
      {showOtp.otp && mobileView ? (
        <OtpVerification
          showOtp={showOtp}
          form={accountForm}
          mobileView={mobileView}
          setShowOtp={setShowOtp}
          code={code}
          inputRef={inputReference}
          handleResendOtp={handleResendOtp}
          setLoginPage={setLoginPage}
          loginPage={loginPage}
          userPhone={userPhone}
          accountValue={accountValue}
          formuse={formuse}
          verifyScreen={verifyScreen}
          setVerifyScreen={setVerifyScreen}
          errorText={errorText}
          setErrorText={setErrorText}
          handleVerifyToAccountPage={handleVerifyToAccountPage}
        />
      ) : (
        <div>
          <div
            className={
              mobileView
                ? 'mt-10 create-account-container slide-top'
                : 'mt-10 create-account-container'
            }
            id="bottom"
          >
            <Form
              onFieldsChange={handleFieldChange}
              form={accountForm}
              layout="vertical"
              name="basic"
            >
              <div className="mt-20">
                <Row justify="center">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      size="large"
                      label="Enter Name"
                      name="tenant_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your name',
                        },
                        ...getFormItemRules({
                          whitespace: true,
                          special: true,
                        }),
                      ]}
                      validateStatus={nameError && 'error'}
                      hasFeedback={nameError}
                    >
                      <Input suffix={<></>} />
                    </Form.Item>
                  </Col>
                  {mobileView ? (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        size="large"
                        name="phone_number"
                        label="Enter Phone Number"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your mobile number',
                          },
                          ...validatePhoneNumber({
                            phone: true,
                            isError,
                            phoneNumber,
                          }),
                        ]}
                      >
                        <PhoneInput
                          country="in"
                          placeholder="Enter your phone number here"
                          value={phoneNumber}
                          onChange={onChangePhoneNumber}
                          countryCodeEditable={false}
                          inputExtraProps={{
                            required: true,
                          }}
                          inputStyle={{ width: '100%' }}
                          suffix={<></>}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <Row style={{ width: '100%' }}>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={phoneColumnSpan()}
                        xl={phoneColumnSpan()}
                      >
                        <Form.Item
                          size="large"
                          name="phone_number"
                          label="Enter Phone Number"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your mobile number',
                            },
                            ...validatePhoneNumber({
                              phone: true,
                              isError,
                              phoneNumber,
                            }),
                          ]}
                          validateStatus={phoneError && 'error'}
                          hasFeedback={phoneError}
                        >
                          <PhoneInput
                            country="in"
                            placeholder="Enter your phone number here"
                            value={phoneNumber}
                            onChange={onChangePhoneNumber}
                            countryCodeEditable={false}
                            inputExtraProps={{
                              required: true,
                            }}
                            inputStyle={{
                              borderColor: phoneError ? 'red' : '',
                              width: '100%',
                            }}
                            suffix={<></>}
                          />
                        </Form.Item>
                      </Col>
                      {otpLoading ? (
                        <>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={12}
                            xl={12}
                            className="loading-otp"
                          >
                            <Spin
                              className="ml-10 mt-10"
                              indicator={<LoadingOutlined />}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          {showOtp.otp && (
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                              <Verification
                                code={code}
                                mobileView={mobileView}
                                setShowOtp={setShowOtp}
                                setLoginPage={setLoginPage}
                                loginPage={loginPage}
                                inputRef={inputReference}
                                errorText={errorText}
                                setErrorText={setErrorText}
                                handleOtp={handleOtp}
                                userPhone={userPhone}
                                accountValue={accountValue}
                                formuse={formuse}
                              />
                            </Col>
                          )}
                          {showOtp.loader && (
                            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                              <OtpVerifyButton
                                showOtp={showOtp}
                                mobileView={mobileView}
                              />
                            </Col>
                          )}
                          {showOtp.verified && (
                            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                              <OtpVerifyButton
                                showOtp={showOtp}
                                mobileView={mobileView}
                              />
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  )}
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      size="large"
                      name="email_address"
                      label="Enter your Email Address"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your email address',
                        },
                        {
                          type: 'email',
                          message: 'Please enter a valid email address',
                        },
                      ]}
                      validateStatus={emailError && 'error'}
                      hasFeedback={emailError}
                    >
                      <Input 
                        suffix={<></>} 
                        onChange={(data)=>{
                          accountForm.setFieldsValue({email_address: toLower(data?.target?.value)})
                        }} 
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      size="large"
                      name="password"
                      label="Enter Password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password',
                        },
                      ]}
                      validateStatus={passwordError && 'error'}
                      hasFeedback={passwordError}
                    >
                      <Input.Password
                        autoComplete="new-password"
                        suffix={<></>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          <div className="right-side-container-next-button" id="bottom-button">
            <NextButton
              showLayout={SHOW_LAYOUT_ACCOUNT}
              handleContinueButton={handleContinueButton}
              handlePreviousButton={handlePreviousButton}
              showOtp={showOtp}
              loading={buttonLoading}
              formAnimation={formAnimation}
              mobileView={mobileView}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default AccountDetailsForm;

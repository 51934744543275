/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Row, Spin, notification } from 'antd';
import 'react-phone-input-2/lib/style.css';
import React, { useEffect, useState } from 'react';
import './index.less';
import { get, map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { getBusinessCategory } from '../../utils/api/url-helper';
import { IsMobileView } from '../../settings/helper-function';
import { subscribeEvent } from '../../components/facebook-pixel-events';
import {
  ONBOARD_ADD_CATEGORY,
  ONBOARD_TEMPLATE,
  SESSION_STORAGE_CATGORY_KEY,
} from '../../settings/constants';

const CategoryList = () => {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const fetchCategories = () => {
    setLoading(true);
    const parameters = {
      fav_category: 1,
    };
    getBusinessCategory(parameters)
      .then((response) => {
        map(get(response, 'data', []), (value) => {
          if (value.category_name === 'Handmade & Artisan Goods') {
            value.category_name = 'Handmade Goods';
          }
          if (value.category_name === 'Jewellery & Accessories') {
            value.category_name = 'Jewellery';
          }
        });
        const data = [
          ...get(response, 'data', []),
          {
            category_name: 'Cant find what you’re looking for?',
          },
        ];
        setCategories(data);
        setLoading(false);
      })
      .catch((error) => {
        notification.error({
          message: get(error, 'error', false),
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    setMobileView(IsMobileView());
    fetchCategories();
  }, []);

  const handleChange = (tag) => {
    subscribeEvent('ZupainSubscribe');
    if (tag) {
      sessionStorage.setItem(
        SESSION_STORAGE_CATGORY_KEY,
        JSON.stringify({ category_uid: tag })
      );
      history.push(ONBOARD_TEMPLATE);
    } else {
      history.push(ONBOARD_ADD_CATEGORY);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="category-main-list-container">
        <div className="mt-20 category-list-container">
          <Row>
            {map(categories, (data) => (
              <Col
                span={mobileView ? 8 : 6}
                key={get(data, 'category_uid', '')}
              >
                <div
                  onClick={() => {
                    handleChange(get(data, 'category_uid', ''));
                  }}
                  className="category-item"
                >
                  <Row>
                    <Col span={24} className="flex-center avatar-animation">
                      {get(data, 'image', false) && (
                        <img
                          className="avatar-img"
                          src={get(data, 'image', '')}
                          alt={get(data, 'category_name', '')}
                        />
                      )}
                    </Col>
                    <Col span={24} className="flex-center">
                      <div
                        className={`${
                          get(data, 'category_uid', false) &&
                          'category-item-name'
                        } slide-right`}
                      >
                        {get(data, 'category_name', '')}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Spin>
  );
};
export default CategoryList;

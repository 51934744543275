import React from 'react';
import { Form, Input, Col } from 'antd';
import { isUndefined } from 'lodash';

const OtpField = (properties) => {
  const { otp, inputRef, formRef, onFinish, nextInputRef } = properties;

  const handleTextChange = (event_) => {
    const { length } = event_.target.value;
    const { id, form, maxLength, value } = event_.target;
    const index = Number(id);
    if (index !== 6 && length === maxLength) nextInputRef.current.focus();
    if (length > maxLength) {
      const name = `otpField${id}`;
      formRef.setFieldsValue({ [name]: value[value.length - 1] });
    }
    formRef.validateFields().then(() => {
      onFinish(formRef.getFieldsValue());
    });
  };

  const handlePaste = (event_) => {
    event_.preventDefault();
    const { form } = event_.target;
    const pastedValue = event_.clipboardData.getData('text');
    const pastedValueLength = pastedValue.length;
    if (/^\d+$/.test(pastedValue) && pastedValueLength === 6) {
      const pastedValues = pastedValue.split('');
      form.elements[pastedValueLength - 1].focus();
      for (let index = 0; index < pastedValueLength; index += 1) {
        const name = `otpField${index + 1}`;
        formRef.setFieldsValue({ [name]: pastedValues[index] });
      }
    }
  };

  const handleKeyDown = (event_) => {
    const { id, form } = event_.target;
    const index = Number(id);
    if (event_.key === 'Backspace') {
      const name = `otpField${id}`;
      const inputFocus = formRef.getFieldValue(name);
      if (inputFocus === '' || isUndefined(inputFocus)) {
        if (index !== 1) form.elements[index - 2].focus();
      }
    }
  };

  const field = (
    <Col span={3} xs={4} className="input-otp input-otp-style">
      <Form.Item name={`otpField${otp}`} className="otp-number-field">
        <Input
          className="text-center"
          id={otp}
          maxLength="1"
          name={`otpField${otp}`}
          size="1"
          min="0"
          max="9"
          ref={inputRef}
          type="number"
          onChange={(event_) => handleTextChange(event_)}
          onPaste={(event_) => handlePaste(event_)}
          onKeyDown={(event_) => handleKeyDown(event_)}
        />
      </Form.Item>
    </Col>
  );
  return field;
};

export default OtpField;

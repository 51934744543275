import React, { useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import _ from 'lodash';
import { userAuthenticate } from '../utils/api/url-helper';
import { FAILED_TO_LOAD, TENANTLIST } from '../settings/constants';

export const AuthContext = createContext({});

const isValidToken = () => {
  const token = localStorage.getItem('token');
  if (token) return true;
  return false;
};

const AuthProvider = (properties) => {
  const history = useHistory();
  const [isAuthenticated, makeAuthenticated] = useState(isValidToken());
  const [loading, setLoading] = useState(false);
  const authenticate = async (values) => {
    setLoading(true);
    try {
      const { data, error } = await userAuthenticate(values);
      if (error) throw new Error(error);
      localStorage.setItem('token', data);
      makeAuthenticated(true);
      setLoading(false);
      history.push(TENANTLIST);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: _.get(error, 'error' || FAILED_TO_LOAD),
      });
    }
  };

  const signout = () => {
    makeAuthenticated(false);
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
        loading,
      }}
    >
      <>{properties.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;

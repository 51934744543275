import moment from 'moment';
import '../containers/tenant-create/index.less';
import React from 'react';
import { get, isEmpty, isUndefined } from 'lodash';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { notification } from 'antd';
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
  INVALID_PHONE_NUMBER,
  OTP_LIMIT_EXCEED,
  SOMETHING_ERROR,
} from './constants';
import * as XLSX from 'xlsx/xlsx';

export function NumberFormater(number) {
  if (number > 999 && number < 1000000) {
    return `${(number / 1000).toFixed(1)}K`; // convert to K for number from > 1000 < 1 million
  }
  if (number > 1000000) {
    return `${(number / 1000000).toFixed(1)}M`; // convert to M for number from > 1 million
  }
  return number;
}

export function fixed(value, number = 2) {
  return Number(value.toFixed(number));
}

export function getBetweenDays(input, value) {
  const {
    date = new Date(),
    count = value,
    unit = 'day',
    fmt = 'YYYY-MM-DD',
  } = input;
  const startOf = moment(date).subtract(count, unit).format(fmt);
  const endOf = moment(date).endOf('day').format(fmt);
  return [startOf, endOf];
}

export const validatePhoneNumber = ({ phone, isError, phoneNumber }) => {
  const rulesData = [];
  if (phone && isError) {
    rulesData.push({
      validator() {
        if (isValidPhoneNumber(`+${phoneNumber}`) === true) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter your Phone Number'));
      },
    });
  }
  return rulesData;
};

export const moreAbout = [
  {
    value: 'This is my first online store!',
    labelOne: 'This is my first online store!',
  },
  {
    value: 'Don’t worry. I am an experienced seller.',
    labelOne: (
      <>
        <div className="first-div">Don’t worry.</div>
        <div className="second-div "> I am an experienced seller.</div>
      </>
    ),
  },
];
export const almostThereOptions = [
  {
    value: 'My own products I buy/make/ship myself',
    labelOne: (
      <>
        <div className="first-div ml-20p">My own products I buy/make/ship</div>
        <div className="second-div ">myself</div>
      </>
    ),
  },
  {
    value: 'Doorshipping via FB, Insta, and more',
    labelOne: (
      <>
        <div className="first-div">Doorshipping via FB, Insta, and</div>
        <div className="second-div "> more</div>
      </>
    ),
  },
  {
    value: 'Prints & designs via 3rd party',
    labelOne: 'Prints & designs via 3rd party',
  },
  {
    value: 'Services - Coaching, consulting, housekeeping',
    labelOne: (
      <>
        <div className="first-div">Services - Coaching,consulting,</div>
        <div className="second-div ">housekeeping</div>
      </>
    ),
  },
  {
    value: 'Digital products-Music, Art, NFTs.',
    labelOne: 'Digital products-Music, Art, NFTs.',
  },
  {
    value: `I’ll decide later`,
    labelOne: `I’ll decide later`,
  },
];
export const fewMoreOptions = [
  {
    value: 'Create a fully customizable online store.',
    labelOne: (
      <>
        <div className="first-div">Create a fully customizable</div>
        <div className="second-div ">online store.</div>
      </>
    ),
  },
  {
    value: 'Reach through social media-FB, Insta, and more.',
    labelOne: (
      <>
        <div className="first-div ml-20p">Reach through social media-FB,</div>
        <div className="second-div ml-20p">Insta, and more.</div>
      </>
    ),
  },
  {
    value: `In person retail, pop-up stores etc.`,
    labelOne: `In person retail, pop-up stores etc.`,
  },
  {
    value: 'Add a buy button in the existing website/ blog.',
    labelOne: (
      <>
        <div className="first-div ml-20p">Add a buy button in the existing</div>
        <div className="second-div ml-20p ">website/ blog.</div>
      </>
    ),
  },
  {
    value: 'List products on online marketplace-Google, Amazon, etc.',
    labelOne: (
      <>
        <div className="first-div ml-30p">List products on online</div>
        <div className="second-div ml-30p">
          {' '}
          marketplace-Google, Amazon, etc.
        </div>
      </>
    ),
  },
  {
    value: 'I’m not sure',
    labelOne: 'I’m not sure',
  },
];

export const moreAboutTitle = [
  {
    titleOne: 'More about you!',
    titleTwo: 'Which of these best describes you?',
  },
];

export const almostTitle = [
  {
    titleOne: 'Almost there!',
    titleTwo: 'What do you plan to sell first?',
  },
];

export const FewMore = [
  {
    titleOne: 'Few more!',
    titleTwo: 'Go wild and choose your selling platform!',
  },
];

export const IsMobileView = () => {
  const windoWidth = get(window, 'innerWidth', 0);
  if (windoWidth < 576) {
    return true;
  }
  return false;
};

export default function getFormItemRules(arguments_) {
  const { required, whitespace, alphanumeric, positiveNumber, special } =
    arguments_;
  const rulesData = [];
  if (required) {
    rulesData.push({ required: true });
  }
  if (whitespace) {
    rulesData.push({
      validator(_, value) {
        if (
          value &&
          (!value.toString().substr(0, 1).trim() ||
            !value.toString().substr(-1).trim())
        ) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Whitespace around the value not allowed');
        }
        return Promise.resolve();
      },
    });
  }
  if (alphanumeric) {
    rulesData.push({
      validator: (_, value) => {
        const regEx = /^(?=.*\d)(?=.*[A-Za-z])([\dA-Za-z]+)$/;
        if (value && !regEx.test(value)) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('coupon code must alphanumeric format');
        }
        return Promise.resolve();
      },
    });
  }
  if (positiveNumber) {
    rulesData.push({
      validator: (_, value) => {
        if (value && value < 0) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Value should not be a negative number');
        }
        return Promise.resolve();
      },
    });
  }
  if (special) {
    rulesData.push({
      validator(_, value) {
        const pattern = /[",.`~'?\\/]/g;
        if (value && value.match(pattern)) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Special characters are not allowed');
        }
        return Promise.resolve();
      },
    });
  }
  return rulesData;
}

export const couponDiscountTypes = [
  {
    value: 'Value',
    label: 'Value',
  },
  {
    value: 'Percentage',
    label: 'Percentage',
  },
];

export const checkImageFileType = (type) => {
  return type === 'image/jpeg' || type === 'image/png' || type === 'image/webp';
};

export const firebaseInitial = () => {
  if (!firebase?.apps?.length) {
    const {
      REACT_APP_FIREBASE_API_KEY,
      REACT_APP_FIREBASE_AUTH_DOMAIN,
      REACT_APP_FIREBASE_PROJECT_ID,
      REACT_APP_FIREBASE_STORAGE_BUCKET,
      REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      REACT_APP_FIREBASE_APP_ID,
      REACT_APP_FIREBASE_MEASUREMENT_ID,
    } = process.env;
    const firebaseConfig = {
      apiKey: REACT_APP_FIREBASE_API_KEY,
      authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: REACT_APP_FIREBASE_APP_ID,
      measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
    };
    firebase.initializeApp(firebaseConfig);
  }
};

export const errorNotification = (message) => {
  notification.error({
    message,
  });
};

export const handleOtpCode = (
  data,
  recaptchaId,
  inputReference,
  setCode,
  setShowOtp,
  setButtonLoading,
  mobileView,
  setVerifySrceen,
  setOtpLoading
) => {
  setButtonLoading(!!mobileView);
  setOtpLoading(!mobileView);
  const { phone, countryCode } = data;
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      recaptchaId,
      {
        size: 'invisible',
      }
    );
  }
  const phoneNumber = !isUndefined(countryCode)
    ? `${countryCode}${phone}`
    : phone;
  const appVerifiers = window.recaptchaVerifier;
  firebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, appVerifiers)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      const otpCode = confirmationResult.verificationId;
      if (inputReference.current) {
        inputReference.current.focus();
      }
      setCode(otpCode);
      setShowOtp({ otp: true, loader: false, verified: false });
      setButtonLoading(false);
      setOtpLoading(false);
      if (mobileView) {
        setVerifySrceen(true);
      }
    })
    .catch((error) => {
      setButtonLoading(false);
      setOtpLoading(false);
      if (error.code === 'auth/too-many-requests') {
        errorNotification(OTP_LIMIT_EXCEED);
      } else if (error.code === 'auth/invalid-phone-number') {
        errorNotification(INVALID_PHONE_NUMBER);
      } else {
        /* eslint-disable no-console */
        console.log(error);
        errorNotification(get(error, 'message', false) || SOMETHING_ERROR);
      }
      setShowOtp({ otp: false, loader: false, verified: false });
    });
};

export const animationClassLeft = (id) => {
  if (document.getElementById(id).classList.contains('slide-left')) {
    document.getElementById(id).classList.remove('slide-left');
    document.getElementById(id).classList.add('slide-left-test');
  } else {
    document.getElementById(id).classList.add('slide-left');
    document.getElementById(id).classList.remove('slide-left-test');
  }
};
export const animationClassLeftRight = (id) => {
  if (document.getElementById(id).classList.contains('slide-right')) {
    document.getElementById(id).classList.remove('slide-right');
    document.getElementById(id).classList.add('slide-right-test');
  } else {
    document.getElementById(id).classList.add('slide-right');
    document.getElementById(id).classList.remove('slide-right-test');
  }
};
export const removeAllSesstionStorage = () => {
  sessionStorage.clear();
};
export const removeSesstionStorage = (key) => {
  sessionStorage.removeItem(key);
};
export const parseJSONSafely = (string) => {
  try {
    return JSON.parse(string);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return {};
  }
};

export const startDateCondition = (value, data) => {
  let diff;
  if (data?.length === 2) {
    diff = data[0].format();
  } else
    switch (value) {
      case 'today': {
        diff = moment().startOf('day').format();
        break;
      }
      case 'yester_day': {
        diff = moment().startOf('day').subtract(1, 'days').format();
        break;
      }
      case 'customize': {
        diff = '';
        break;
      }
      default: {
        diff = moment().subtract(data?.count, 'days').format();
      }
    }
  return diff;
};

export const endDateCondition = (value, data) => {
  let diff;
  if (data?.length === 2) {
    diff = moment(data[1]).format();
  } else if (value === 'yester_day') {
    diff = moment().startOf('day').format();
  } else if (value === 'customize') {
    diff = '';
  } else {
    diff = moment().format();
  }
  return diff;
};

export const reportDateFormation = (value) =>{
  return  moment(value).format('YYYY-MM-DD HH:mm:ss')
  
}
export const downloadDisability = (data) =>{
  return isEmpty(data)
}
export  const excelDownload = (tableData, fileName) => {
  try {
    const columnkeys = Object.keys(tableData[0]);
  const ws = XLSX.utils.json_to_sheet(tableData, { header: columnkeys });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const arrayBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
  });

  const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.xlsx`;
  a.click();
  window.URL.revokeObjectURL(url);
  } catch (error) {
      notification.error({
          message: 'Failed to download details',
        });
  }
  
};
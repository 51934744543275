import 'react-phone-input-2/lib/style.css';
import React, { useEffect } from 'react';
import './index.less';
import { ZUPAIN_LOGO } from '../../settings/constants';
import { animationClassLeft } from '../../settings/helper-function';

const Logo = (properties) => {
  const { showLayout } = properties;
  useEffect(() => {
    if (showLayout) {
      animationClassLeft('left');
    }
  }, [showLayout]);

  return (
    <div id="left">
      <img src={ZUPAIN_LOGO} className="zupain-logo-img" alt="logo" />
    </div>
  );
};
export default Logo;

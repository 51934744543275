import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, Row, Statistic } from 'antd';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useHistory } from 'react-router-dom';
import OtpField from './otp-field';
import {
  INCORRECT_CODE,
  ONBOARD_WEBSITE,
  SESSION_STORAGE_ACCOUNT_KEY,
} from '../../settings/constants';
import OtpVerifyButton from './otp-verify-buuton';
import { ReactComponent as InfoIcon } from '../../assets/images/info-icon.svg';
import { IsMobileView } from '../../settings/helper-function';

const { Countdown } = Statistic;

const Verification = (properties) => {
  const {
    code,
    setShowOtp,
    inputRef,
    showOtp,
    setLoginPage,
    loginPage,
    errorText,
    setErrorText,
    handleOtp,
    userPhone,
    accountValue,
    formuse,
  } = properties;

  const [mobileView, setMobileView] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setMobileView(IsMobileView());
  }, []);

  const handleResendOtp = () => {
    setLoginPage(true);
    handleOtp(userPhone, 'signing-button');
    formuse.resetFields();
    setErrorText('');
  };

  const handleSubmit = (codeInput) => {
    setShowOtp(
      !mobileView ? { otp: false, loader: true } : { otp: true, loader: true }
    );
    const credential = firebase.auth.PhoneAuthProvider.credential(
      code,
      codeInput
    );
    firebase
      .auth()
      .signInWithCredential(credential)
      .then(() => {
        setShowOtp(
          !mobileView
            ? { otp: false, loader: false, verified: true }
            : { otp: true, loader: false, verified: true }
        );
        if (mobileView) {
          sessionStorage.setItem(
            SESSION_STORAGE_ACCOUNT_KEY,
            JSON.stringify({ account: accountValue })
          );
          history.push(ONBOARD_WEBSITE);
        }
      })
      .catch(() => {
        setErrorText(INCORRECT_CODE);
        setShowOtp({ otp: true, loader: false });
      });
  };
  const onFinish = (values) => {
    const { otpField1, otpField2, otpField3, otpField4, otpField5, otpField6 } =
      values;
    const codeInput = `${otpField1}${otpField2}${otpField3}${otpField4}${otpField5}${otpField6}`;
    const pattern = /^[0-9\b]+$/;
    if (codeInput.length !== 6 || codeInput.includes('undefined')) {
      setErrorText('Please Enter OTP!');
    } else if (pattern.test(codeInput)) {
      setErrorText('');
      if (!mobileView) {
        handleSubmit(codeInput);
      }
    } else {
      setErrorText('Please Enter Valid OTP!');
    }
  };

  const handleClickVerifyButton = () => {
    const values = formuse.getFieldsValue();
    const { otpField1, otpField2, otpField3, otpField4, otpField5, otpField6 } =
      values;
    const codeInput = `${otpField1}${otpField2}${otpField3}${otpField4}${otpField5}${otpField6}`;
    const pattern = /^[0-9\b]+$/;
    if (codeInput.length !== 6 || codeInput.includes('undefined')) {
      setErrorText('Please Enter OTP!');
    } else if (pattern.test(codeInput)) {
      setErrorText('');
      handleSubmit(codeInput);
    } else {
      setErrorText('Please Enter Valid OTP!');
    }
  };

  const countStyle = { fontSize: '15px', marginLeft: '-4px' };

  const timer = useMemo(() => {
    setLoginPage(true);
    return (
      <Countdown
        value={Date.now() + 10 * 12000}
        prefix="Resend in"
        format="mm:ss"
        valueStyle={countStyle}
        onFinish={() => setLoginPage(false)}
        className="phone-popup-otp"
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const handleVerifyButton = () => {
    if (showOtp.loader) {
      return <OtpVerifyButton showOtp={showOtp} mobileView={mobileView} />;
    }
    if (showOtp.verified) {
      return <OtpVerifyButton showOtp={showOtp} mobileView={mobileView} />;
    }
    return (
      <Button type="primary" onClick={handleClickVerifyButton}>
        Verify
      </Button>
    );
  };

  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const input5 = useRef(null);
  const input6 = useRef(null);

  return (
    <div className="verification">
      {mobileView === false && (
        <div className="ver-heading">
          <div className="comfirm-text">Confirm OTP</div>
          <div className="flex-end">
            {loginPage ? (
              <div
                onClick={handleResendOtp}
                className="resend-otp"
                id="resend-otp-btn"
                type="link"
                role="presentation"
              >
                {timer}
              </div>
            ) : (
              <div
                className="resend-otp"
                onClick={handleResendOtp}
                id="resend-otp-btn"
                type="link"
                role="presentation"
              >
                Resend Now
              </div>
            )}
          </div>
        </div>
      )}
      <div className="otp-fields">
        <Form form={formuse} name="basic" className="mt-3">
          <Row justify={mobileView ? 'space-around' : 'space-between'}>
            <OtpField
              otp={1}
              formRef={formuse}
              inputRef={inputRef}
              nextInputRef={input2}
              onFinish={onFinish}
              errorText={errorText}
            />
            <OtpField
              otp={2}
              formRef={formuse}
              inputRef={input2}
              nextInputRef={input3}
              onFinish={onFinish}
              errorText={errorText}
            />
            <OtpField
              otp={3}
              formRef={formuse}
              inputRef={input3}
              nextInputRef={input4}
              onFinish={onFinish}
              errorText={errorText}
            />
            <OtpField
              otp={4}
              formRef={formuse}
              inputRef={input4}
              nextInputRef={input5}
              onFinish={onFinish}
              errorText={errorText}
            />
            <OtpField
              otp={5}
              formRef={formuse}
              inputRef={input5}
              nextInputRef={input6}
              onFinish={onFinish}
              errorText={errorText}
            />
            <OtpField
              otp={6}
              formRef={formuse}
              inputRef={input6}
              onFinish={onFinish}
              errorText={errorText}
            />
          </Row>
          {errorText && (
            <div className="error-text">
              <InfoIcon />
              <div style={{ marginLeft: '4px' }}>{errorText}</div>
            </div>
          )}
        </Form>
      </div>
      {mobileView && (
        <div style={{ marginBottom: '18%' }}>
          <div className="otp-footer-div">
            <span className="otp-ahref">Didn’t receive any code?</span>
            <br />
            <Button
              className="otp-ahref"
              style={{ color: '#472DC5' }}
              onClick={handleResendOtp}
              type="link"
            >
              Resend Code
            </Button>
          </div>
          <div className="right-side-container-next-button next-button-container">
            {handleVerifyButton()}
          </div>
        </div>
      )}
    </div>
  );
};

export default Verification;

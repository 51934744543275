import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

const OtpVerifyButton = (properties) => {
  const { showOtp, mobileView } = properties;
  return (
    <div className="otp-verify-button-container">
      {!mobileView && <div className="verify-label">Confirm OTP</div>}
      {showOtp.verified ? (
        <div className="Verified-button">
          <Button icon={<CheckCircleFilled />}>OTP Verified</Button>
        </div>
      ) : (
        <div className="verify-button-loading">
          <Button loading>Verifying OTP</Button>
        </div>
      )}
    </div>
  );
};

export default OtpVerifyButton;

export const signInWithEmailEvent = () => {
  if (typeof window.fbq !== 'undefined') window.fbq('track', 'SignInWithEmail');
};

export const signInWithGoogleEvent = () => {
  if (typeof window.fbq !== 'undefined')
    window.fbq('track', 'SignInWithGoogle');
};

export const subscribeEvent = (eventName) => {
  if (typeof window.fbq !== 'undefined') window.fbq('track', eventName);
};

export const startFreeTrialEvent = () => {
  if (typeof window.fbq !== 'undefined') window.fbq('track', 'StartFreeTrial');
};

export const paymentEvent = (paymentEventName) => {
  if (typeof window.fbq !== 'undefined') window.fbq('track', paymentEventName);
};

export const planDurationEvent = (durationEventName) => {
  if (typeof window.fbq !== 'undefined') window.fbq('track', durationEventName);
};

export const continueEvent = (eventName) => {
  if (typeof window.fbq !== 'undefined') window.fbq('track', eventName);
};

export const skipEvent = (eventName) => {
  if (typeof window.fbq !== 'undefined') window.fbq('track', eventName);
};

import React from 'react';

const permissionError = () => {
  return (
    <div className="error-container">
      <div>
        <h1>404</h1>
        <h2>We are sorry but the page you are looking for was not found...</h2>
      </div>
    </div>
  );
};

export default permissionError;

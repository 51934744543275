import { Button, Col, Row } from 'antd';
import React from 'react';
import {
  SHOW_LAYOUT_ACCOUNT,
  SHOW_LAYOUT_CATEGORY,
  SHOW_LAYOUT_CHOOSE_WEBSITE_STORE,
  SHOW_LAYOUT_STORE,
} from '../../settings/constants';

const NextButton = (properties) => {
  const {
    loading,
    showLayout,
    handleContinueButton,
    handlePreviousButton,
    showOtp,
    mobileView,
  } = properties;

  const hostFromClic = window?.location?.hostname?.startsWith('clic');

  return (
    <Row className="next-button-container flex-center">
      <Col xs={0} sm={0} md={12} lg={8} xl={8}>
        <Button onClick={handlePreviousButton} className='flex-center'>
          {showLayout === SHOW_LAYOUT_CATEGORY ? 'Skip' : 'Back'}
        </Button>
      </Col>
      {showLayout !== SHOW_LAYOUT_CHOOSE_WEBSITE_STORE && (
        <Col xs={24} sm={24} md={12} lg={14} xl={14}>
          <Button
            loading={loading}
            disabled={
              !mobileView
                ? showLayout === SHOW_LAYOUT_ACCOUNT && !showOtp.verified
                : false
            }
            onClick={handleContinueButton}
            type="primary"
            className='flex-center'
          >
            {hostFromClic && showLayout === SHOW_LAYOUT_STORE
              ? 'Take me to store '
              : 'Continue'}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default NextButton;
